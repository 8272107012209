import { gql } from '@apollo/client';

import { STRUCTURED_BODY_BOOKIE, MULTI_LANG_STRING } from '@store/fragments';

const BOOKIE_GRADES = gql`
	fragment bookieGrades on BookieGrades {
		tribuna
		allOffers
		welcomeOffer
		odds
		loginRegistrationProcess
		exchangeDepositWithdrawalsOptions
		security
		broadcasts
		support
		usability
		iosApp
		androidApp
	}
`;

const BOOKIE_REDUCED_GRADES = gql`
	fragment bookieReducedGrades on BookieReducedGrades {
		tribuna
		allOffers
		welcomeOffer
		odds
		loginRegistrationProcess
		exchangeDepositWithdrawalsOptions
		security
		broadcasts
		support
		usability
		iosApp
		androidApp
	}
`;

const BOOKIE_DATA_BY_GEO = gql`
	${BOOKIE_GRADES}
	${BOOKIE_REDUCED_GRADES}
	${STRUCTURED_BODY_BOOKIE}
	${MULTI_LANG_STRING}
	fragment bookieDataByGeo on BookieGeoSet {
		countryId
		isPartner
		seo {
			title
			h1
			description
			canonical
		}
		currency {
			id
			name {
				...multiLangString
			}
		}
		maxSum
		minDep
		minBet
		promoCodes
		license
		address
		mainCurrencies {
			id
			name {
				...multiLangString
			}
		}
		mainPaymentSystems
		faqs {
			title
			structuredBody {
				...StructuredBodyFragmentForBookie
			}
		}
		grades {
			...bookieGrades
		}
		reducedGrades {
			...bookieReducedGrades
		}
		bonuses {
			id
			bookieId
			countryId
			name
			description
			shortDescription
			imageURL
			priority
			bonusType
			bonusText
			amount
			link
			promoCode
			currency {
				id
				name {
					...multiLangString
				}
			}
		}
	}
`;

export const bookieQueries = {
	getBookie: gql`
		${BOOKIE_GRADES}
		${BOOKIE_DATA_BY_GEO}
		${BOOKIE_REDUCED_GRADES}
		${STRUCTURED_BODY_BOOKIE}
		${MULTI_LANG_STRING}
		query GetBookie($id: ID!) {
			bookieQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					url {
						link
						linkType
						linkText
					}
					name
					isBanned
					logoURL
					updatedAt
					publicationTime
					appIOSButtonURL
					appAndroidButtonURL
					pros
					cons
					author {
						id
						name
						bio
						avatar {
							webp(width: 60, height: 60, quality: 80)
						}
					}
					seo {
						title
						h1
						description
						canonical
					}
					faqs {
						title
						structuredBody {
							...StructuredBodyFragmentForBookie
						}
					}
					bio {
						betTypes {
							id
							name {
								...multiLangString
							}
						}
						liveChat
						liveBets
						platforms
						products
						currencies {
							id
							name {
								...multiLangString
							}
						}
						paymentSystems
						foundationYear
						owner
						siteLanguages {
							id
							name {
								...multiLangString
							}
						}
						licenceCountries {
							id
							name {
								...multiLangString
							}
						}
						bannedCountries {
							id
							name {
								...multiLangString
							}
						}
					}
					structuredSeoText {
						...StructuredBodyFragmentForBookie
					}
					dataByGeo {
						...bookieDataByGeo
					}
					grades {
						...bookieGrades
					}
					reducedGrades {
						...bookieReducedGrades
					}
					existingTranslations
				}
			}
		}
	`,

	getCountryFlag: gql`
		query GetCountryFlag($countryId: ID!, $source: statCountryIDList) {
			stat {
				football {
					stat_country(id: $countryId, source: $source) {
						name
						picture(productType: TRIBUNA, format: PNG) {
							main
						}
					}
				}
			}
		}
	`,

	getBookieRating: gql`
		${BOOKIE_GRADES}
		${BOOKIE_REDUCED_GRADES}
		${STRUCTURED_BODY_BOOKIE}
		query GetBookieRating($id: ID!, $language: Language) {
			bookieQueries {
				getRating(input: { id: $id, idType: HRU }) {
					id
					hru
					name
					countryId
					ratingType
					existingTranslations
					structuredDescription {
						...StructuredBodyFragmentForBookie
					}
					structuredSeoText {
						...StructuredBodyFragmentForBookie
					}
					sortType
					faqs {
						title
						structuredBody {
							...StructuredBodyFragmentForBookie
						}
					}
					seo {
						h1
						title
						description
						canonical
					}
					author {
						id
						name
						bio
						avatar {
							webp(width: 60, height: 60, quality: 80)
						}
					}
					topBookies {
						id
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						appIOSButtonURL
						appAndroidButtonURL
						structuredSeoText {
							type
						}
						grades {
							...bookieGrades
						}
						reducedGrades {
							...bookieReducedGrades
						}
						dataByGeo {
							isPartner
							grades {
								...bookieGrades
							}
							reducedGrades {
								...bookieReducedGrades
							}
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
						}
					}
					otherBookies {
						id
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						appIOSButtonURL
						appAndroidButtonURL
						structuredSeoText {
							type
						}
						grades {
							...bookieGrades
						}
						reducedGrades {
							...bookieReducedGrades
						}
					}
					updatedAt
				}
			}
		}
	`,

	getBookieRatingListing: gql`
		query GetRatingListing(
			$onlyRatingsFromMenu: Boolean!
			$priorityType: PriorityType!
			$language: Language
			$limit: Int
		) {
			bookieQueries {
				getRatingListing(
					input: {
						onlyRatingsFromMenu: $onlyRatingsFromMenu
						priorityType: $priorityType
						limit: $limit
					}
				) {
					hru
					name
					bookies {
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						structuredSeoText {
							type
						}
						dataByGeo {
							isPartner
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
						}
					}
				}
			}
		}
	`,

	getBookieWidgetLinks: gql`
		query GetBookieWidgetLinks($id: ID!) {
			bookieQueries {
				getRating(input: { id: $id, idType: HRU, limit: 5 }) {
					topBookies {
						hru
						name
						existingTranslations
					}
					otherBookies {
						hru
						name
						existingTranslations
					}
				}
			}
		}
	`,

	getBookieWidgetData: gql`
		query GetBookieWidgetData($id: ID!, $language: Language, $limit: Int) {
			bookieQueries {
				getRating(input: { id: $id, idType: HRU, limit: $limit }) {
					id
					hru
					name
					totalBookiesCount
					topBookies {
						id
						hru
						name
						existingTranslations
						logoURL
						url {
							link
							linkText
						}
						grades {
							tribuna
						}
						dataByGeo {
							isPartner
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
							grades {
								tribuna
							}
						}
					}
					otherBookies {
						id
						hru
						name
						logoURL
						existingTranslations
						url {
							link
							linkText
						}
					}
				}
			}
		}
	`,

	getBookieStickyButton: gql`
		query GetBookieStickyButton($id: ID!) {
			bookieQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					url {
						link
					}
					name
					isBanned
					logoURL
					dataByGeo {
						bonuses {
							bonusType
							priority
							bonusText
							amount
							link
							promoCode
							currency {
								id
							}
						}
					}
				}
			}
		}
	`,
};
