import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { setContext, setUser } from '@sentry/nextjs';
import {
	FirebaseSigninType,
	User,
	UserBanType,
	UserSigninResult,
} from '@ui-kit/main-api-types';
import { FirebaseErrors } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import { v4 as uuidv4 } from 'uuid';

import { client as clientv2 } from '@apollo-client-v2';
import { sendAnalytics } from '@utils/helpers';
import LocalStorage from '@utils/localstorage';

import { pingDevice } from './actions';
import mutations from './mutations';
import queries from './queries';
import { UserState, UserActions } from './types';
import { createImmerSlice } from '../index';

export const useUserStore = createImmerSlice<UserState & UserActions>(
	(set, get) => ({
		user: {
			authToken: null,
			deviceId: null,
			hasUserLoggedIn: false,
			roles: [],
			banType: null,
			info: null,
			loginChecked: false,
			isLoginModalOpen: false,
			isSignUpModalOpen: false,
			isForgotPasswordOpen: false,
			isConfirmRegistrationOpen: false,
			isSuccessVerificationOpen: false,
			error: null,
		},
		getDeviceId: () => {
			const userStoreDeviceId = get().user.deviceId;
			let deviceId;
			if (!userStoreDeviceId) {
				deviceId = LocalStorage.get('tribuna:deviceId');
				if (!deviceId) {
					deviceId = uuidv4();
					LocalStorage.set('tribuna:deviceId', deviceId);
				}
			} else {
				deviceId = userStoreDeviceId;
			}
			pingDevice(deviceId);
			set(state => {
				state.user.deviceId = deviceId;
			});
		},
		setUserData: (user, token) => {
			const banType: UserBanType | null = user.banned ? user.banned.type : null;

			if (token) {
				LocalStorage.set('tribuna:login:token', token);
			}

			get().getDeviceId();
			set(state => {
				state.user.authToken = token;
				state.user.info = user;
				state.user.hasUserLoggedIn = true;
				state.user.roles = user?.roles?.length ? user.roles : [];
				state.user.banType = banType;
			});
		},
		googleLogin: async authToken => {
			try {
				const userFetchRes: FetchResult<{
					authMutations: { googleSignin: UserSigninResult };
				}> = await clientv2.mutate({
					mutation: mutations.googleLogin,
					variables: {
						authToken,
					},
				});

				const userFetchResData =
					userFetchRes.data?.authMutations?.googleSignin?.session;
				const user = userFetchResData?.user;
				const token = userFetchResData?.token;

				get().setUserData(user, token);
			} catch (err) {
				console.info({ err });
			}
		},
		firebaseLogin: async (
			authToken,
			signinType = FirebaseSigninType.EmailPassword,
		) => {
			try {
				window.localStorage.clear();

				const userFetchRes: FetchResult<{
					authMutations: { firebaseSignin: UserSigninResult };
				}> = await clientv2.mutate({
					mutation: mutations.firebaseLogin,
					variables: {
						authToken,
						signinType,
					},
				});
				const session =
					userFetchRes.data?.authMutations?.firebaseSignin?.session;
				const user = session?.user;
				const token = session?.token;
				get().setUserData(user, token);
			} catch (err) {
				console.info({ err });
				if (err?.message?.includes('email not verified')) {
					get().setError(FirebaseErrors.EmailNotVerified);
					get().closeLoginModal();
					get().closeSignUpModal();
					get().openConfirmRegistrationModal();
				}
			}
		},
		loginCheck: async () => {
			const token = LocalStorage.get('tribuna:login:token');
			if (token) {
				try {
					const currentUserFetchResult: ApolloQueryResult<{
						userQueries: { current: User };
					}> = await clientv2.query({
						query: queries.currentUser,
						context: {
							headers: {
								'x-auth-token': token,
							},
						},
					});
					const currentUser = currentUserFetchResult.data.userQueries?.current;

					get().setUserData(currentUser, token);

					set(state => {
						state.user.loginChecked = true;
					});

					const currentUserId = currentUser?.id;

					window.localStorage.setItem('tribuna:user:id', currentUserId);

					setContext('User', {
						id: currentUserId,
						roles: currentUser?.roles?.length
							? JSON.stringify(currentUser?.roles)
							: null,
					});
					setUser({
						id: currentUserId,
						email: currentUser?.email,
						username: currentUser?.name || currentUser?.nickname,
						roles: currentUser?.roles?.length
							? JSON.stringify(currentUser?.roles)
							: null,
					});
				} catch (err) {
					set(state => {
						state.user.hasUserLoggedIn = false;
					});
					LocalStorage.remove('tribuna:login:token');
					window.localStorage.removeItem('tribuna:user:id');
				}
			} else {
				set(state => {
					state.user.hasUserLoggedIn = false;
				});
				window.localStorage.removeItem('tribuna:user:id');
				set(state => {
					state.user.loginChecked = true;
				});
			}
		},
		userLogout: async () => {
			const userStoreDeviceId = get().user.deviceId;
			LocalStorage.remove('tribuna:login:token');
			window.localStorage.removeItem('tribuna:user:id');

			set(state => {
				state.user.authToken = null;
				state.user.info = null;
				state.user.hasUserLoggedIn = false;
				state.user.roles = [];
				state.user.banType = null;
			});

			await pingDevice(userStoreDeviceId);
		},
		openLoginModal: event => {
			event?.stopPropagation();
			sendAnalytics({
				category: 'sign',
				name: 'view',
				label: 'popup',
			});
			set(state => {
				state.user.isLoginModalOpen = true;
			});
		},
		closeLoginModal: () => {
			set(state => {
				state.user.isLoginModalOpen = false;
			});
		},
		openForgotPasswordModal: () => {
			set(state => {
				state.user.isForgotPasswordOpen = true;
				state.user.isLoginModalOpen = false;
			});
		},
		closeForgotPasswordModal: () => {
			set(state => {
				state.user.isForgotPasswordOpen = false;
			});
		},
		openSignUpModal: () => {
			set(state => {
				state.user.isSignUpModalOpen = true;
			});
		},
		closeSignUpModal: () => {
			set(state => {
				state.user.isSignUpModalOpen = false;
			});
		},
		setError: err => {
			set(state => {
				state.user.error = err;
			});
		},
		openSuccessVerificationModal: () => {
			set(state => {
				state.user.isSuccessVerificationOpen = true;
			});
		},
		closeSuccessVerificationModal: () => {
			set(state => {
				state.user.isSuccessVerificationOpen = false;
			});
		},
		openConfirmRegistrationModal: () => {
			set(state => {
				state.user.isConfirmRegistrationOpen = true;
			});
		},
		closeConfirmRegistrationModal: () => {
			set(state => {
				state.user.isConfirmRegistrationOpen = false;
			});
		},
		recreateEmailAuthForUser: async email => {
			const token = get().user.authToken;

			await clientv2.mutate({
				mutation: mutations.recreateEmailAuthForUser,
				variables: {
					email,
				},
				context: {
					headers: {
						'x-auth-token': token,
					},
				},
			});
		},
	}),
);
