import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { FooterBlock } from '@ui-kit/main-api-types';
import { SportProvider } from '@ui-kit/main-frontend-ui-kit/dist/src/context/SportContext';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import '@ui-kit/statistics-ui-kit/dist/index.css';
import { GetStaticPropsContext } from 'next';
import App, { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import ErrorPage from 'next/error';
import { Montserrat, Roboto } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextIntlClientProvider } from 'next-intl';
import { ThemeProvider } from 'next-themes';

import { changeGateway, initializeApollo } from '@apollo-client';
import {
	changeGateway as changeGatewayV2,
	initializeApollo as initializeApolloV2,
} from '@apollo-client-v2';
import '@ui-kit/colors/index.css';

import { getFooterLinks } from '@store/footer/actions';
import {
	getAppnameFromNewsHru,
	getName,
	isEditorialClub,
} from '@utils/helpers';
import { locales } from '@utils/navigations_intl';
import { isMobileDeviceMDNCheck } from '@utils/tools';

import MainLayout from '../layouts/main';
import '../styles/globals.scss';

const Alerts = dynamic(() => import('@components/Alerts'), {
	ssr: false,
});

const AppBatchScriptsComponent = dynamic(
	() => import('../utils/appBatchScriptsComponent'),
	{
		ssr: false,
	},
);

const roboto = Roboto({
	weight: ['100', '300', '400', '500', '700', '900'],
	preload: true,
	subsets: ['latin'],
	variable: '--roboto-font',
	display: 'swap',
});
const montserrat = Montserrat({
	weight: 'variable',
	preload: true,
	subsets: ['latin'],
	variable: '--montserrat-font',
});
export function reportWebVitals(metric) {
	console.info({ metric });
}
const WrappedApp = ({
	Component,
	pageProps,
	tMessages,
	footerLinks,
	isItBot,
	locale,
}: AppProps & {
	footerLinks: FooterBlock[];
	tMessages: any;
	isItBot: boolean;
	locale: string;
}) => {
	const router = useRouter();
	const { pathname, query, asPath } = router;
	router.locale = locale || 'en';
	router.locales = locales;
	const isMobile = isMobileDeviceMDNCheck();
	dayjs.locale(locale);
	const client = initializeApollo({ lang: locale });
	const clientV2 = initializeApolloV2({ lang: locale });
	if (locale === 'default' && pathname !== '/service-ping' && asPath !== '/') {
		return <ErrorPage statusCode={404} />;
	}
	const newsHru = Array.isArray(query?.id) ? query?.id[0] : query?.id;
	const appnameFromHru = getAppnameFromNewsHru({
		newsHru,
	});
	changeGateway({
		lang: locale,
		appname: appnameFromHru,
	});

	if (query?.clubId) {
		const appName = getName(query?.clubId);
		const isEditorial = isEditorialClub({ alias: appName, lang: locale });
		isEditorial &&
			changeGateway({
				lang: locale,
				appname: appName,
			});
	}

	useEffect(() => {
		const hash = window.location.hash;
		if (!hash) {
			window.scrollTo(0, 0);
		}
	}, [pathname]);
	const isItCreatePostMobilePage = pathname.indexOf('/create-post-mobile') > -1;

	console.info({ locale, query, isItBot }, '_app');

	return (
		<NextIntlClientProvider
			locale={locale}
			timeZone="Europe/Vienna"
			messages={tMessages}
		>
			<ApolloProvider client={client}>
				<ApolloProvider client={clientV2}>
					<Head>
						<meta
							name="viewport"
							content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
						/>
					</Head>
					<ThemeProvider enableSystem={false}>
						<SportProvider>
							<MainLayout
								className={`${roboto.variable} ${montserrat.variable}`}
								footerLinks={footerLinks}
							>
								<Component {...pageProps} />
							</MainLayout>
							{!isItCreatePostMobilePage && <AppBatchScriptsComponent />}
						</SportProvider>
					</ThemeProvider>
					<Alerts />
				</ApolloProvider>
			</ApolloProvider>
		</NextIntlClientProvider>
	);
};

WrappedApp.getInitialProps = async context => {
	const { query = {} } = context.router;
	const { res } = context?.ctx;
	const localePre = (query.locale as string) || (query.lang as string);
	const locale = localePre?.toLowerCase() || 'en';
	// context.router['locale'] = localePre;
	context.ctx['locale'] = localePre;
	// context.router['lang'] = localePre;
	context.ctx['lang'] = localePre;
	const newsHru = Array.isArray(query?.id) ? query?.id[0] : query?.id;

	const appnameFromHru = getAppnameFromNewsHru({
		newsHru,
	});
	const tMessages = (await getTranslations({ params: { locale } }))?.messages;
	const footerLinks = await getFooterLinks(locale as string);
	changeGateway({
		lang: locale,
		appname: appnameFromHru,
	});
	changeGatewayV2({
		lang: locale,
		appname: appnameFromHru,
	});

	if (query?.clubId) {
		const appName = getName(query?.clubId);
		const isEditorial = isEditorialClub({ alias: appName, lang: locale });
		isEditorial &&
			changeGateway({
				lang: locale,
				appname: appName,
			});
		isEditorial &&
			changeGatewayV2({
				lang: locale,
				appname: appName,
			});
	}
	const appProps = await App.getInitialProps({ ...context });
	if (`${res?.statusCode}`.indexOf('40') > -1) {
		res.setHeader('cache-control', 'public, max-age=30');
	}
	if (
		`${res?.statusCode}`.indexOf('30') > -1 ||
		`${res?.statusCode}`.indexOf('50') > -1
	) {
		res.setHeader('cache-control', 'no-cache');
	}

	return {
		...appProps,
		tMessages,
		footerLinks,
		locale,
		isItBot: query?.isItBot === 'true',
	};
};

const getTranslations = async ({ params }: GetStaticPropsContext) => {
	const { locale = 'en' } = params;
	if (!locales.includes(locale as string)) {
		return { messages: {} };
	}
	let translationsFile = null;
	let matchesFile = null;
	let onlinesFile = null;
	let clubs = null;

	let bookies = null;
	let league = null;
	let news = null;
	let person = null;
	let profile = null;
	let standings = null;
	let tags = null;
	let match = null;
	let matchSeo = null;
	let subscriptionSeo = null;
	let seo = null;
	let technical = null;

	if (
		locale === 'service-ping' ||
		locale === 'current-geo' ||
		locale === 'default'
	) {
		translationsFile = {};
	} else {
		translationsFile = (await import(`../locales/${locale}/translations.json`))
			.default;
		matchesFile = (await import(`../locales/${locale}/matches.json`)).default;
		onlinesFile = (await import(`../locales/${locale}/online.json`)).default;

		bookies = (await import(`../locales/${locale}/bookies.json`)).default;

		clubs = (await import(`../locales/${locale}/clubs.json`)).default;
		league = (await import(`../locales/${locale}/league.json`)).default;
		news = (await import(`../locales/${locale}/news.json`)).default;
		person = (await import(`../locales/${locale}/person.json`)).default;
		profile = (await import(`../locales/${locale}/profile.json`)).default;
		standings = (await import(`../locales/${locale}/standings.json`)).default;
		tags = (await import(`../locales/${locale}/tags.json`)).default;
		match = (await import(`../locales/${locale}/match.json`)).default;
		matchSeo = (await import(`../locales/${locale}/matchSeo.js`)).default;
		subscriptionSeo = (
			await import(`../locales/${locale}/subscriptionSeo.json`)
		).default;
		seo = (await import(`../locales/${locale}/seo.json`)).default;
		technical = (await import(`../locales/${locale}/technical.json`)).default;
	}

	return {
		messages: {
			translations: translationsFile,
			matches: matchesFile,
			online: onlinesFile,
			bookies,
			clubs,
			league,
			news,
			person,
			profile,
			standings,
			tags,
			match,
			matchSeo,
			subscriptionSeo,
			seo,
			technical,
		},
	};
};

export default WrappedApp;
