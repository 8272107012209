import {
	Bookie,
	BookieBio,
	BookieGrades,
	Casino,
	CasinoBio,
	CasinoGrades,
	BookieRatingItem,
	CasinoRatingItem,
	CurrencyId,
	BookieRating,
	CasinoRating,
	BetReview,
	BetPlatformType,
	SportId,
	News,
	Post,
	TagType,
} from '@ui-kit/main-api-types';
import { SPORT_NEWS_PAGE_URLS } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/constants';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import {
	getBonus,
	getBonusText,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import { DropdownItem } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
	getBookieStickyButton,
	getBookieWidgetData,
	getBookieWidgetLinks,
} from '@store/bookies/actions';
import {
	getCasinoStickyButton,
	getCasinoWidgetData,
	getCasinoWidgetLinks,
} from '@store/casino/actions';
import {
	BookieBioType,
	LANG,
	RbWidgetData,
	Tab,
	TypeColorTag,
} from '@utils/types';

import { TRIBUNA_COM_URL } from './constants';
import { addIfObj, getCoutryCode, preventXss } from './helpers';

interface IGetBookieBio {
	t: any;
	locale: LANG;
	bookie: Bookie | Casino;
	isBookmaker: boolean;
}

export const getBookieBio = ({
	t,
	locale,
	bookie,
	isBookmaker,
}: IGetBookieBio): BookieBioType => {
	const bookieBio = bookie?.bio;
	const dataByGeo = bookie?.dataByGeo;
	const promocodes = dataByGeo?.promoCodes;

	const maxWithdrawalCurrency =
		dataByGeo?.currency?.id === CurrencyId.Usdt
			? '₮'
			: getSymbolFromCurrency(dataByGeo?.currency?.id);

	const sports = isBookmaker
		? (bookieBio as BookieBio)?.betTypes?.map(({ name }) => name?.[locale])
		: (bookieBio as CasinoBio)?.providers?.map(({ name }) => name?.[locale]);

	return {
		promocodes: {
			title: t('bookies.promocodes'),
			list: promocodes ? [promocodes] : [],
			isCopied: true,
			isSpoiler: true,
			tagColorType: TypeColorTag.BLUE,
		},
		sports: {
			title: isBookmaker ? t('bookies.sports') : t('bookies.providers'),
			list: sports,
			isSpoiler: true,
			tagColorType: TypeColorTag.BLUE,
		},
		liveChat: {
			title: t('bookies.liveChat'),
			isTrue: bookieBio?.liveChat,
		},
		liveBet: {
			title: t('bookies.liveBets'),
			isTrue: (bookieBio as BookieBio)?.liveBets,
		},
		platforms: {
			title: t('bookies.platforms'),
			list: bookieBio?.platforms,
			isSpoiler: bookieBio?.platforms?.length > 1,
			tagColorType: TypeColorTag.GREY,
		},
		maxWithdrawalAmount: dataByGeo?.maxSum && {
			title: t('bookies.maxWithdrawalAmountShort'),
			list: [`${dataByGeo?.maxSum} ${maxWithdrawalCurrency}`],
			isSpoiler: true,
			tagColorType: TypeColorTag.GREY,
		},
		currencies: {
			withPrompts: true,
			title: t('bookies.currencies'),
			list: bookieBio?.currencies,
			isSpoiler: bookieBio?.currencies?.length > 2,
			tagColorType: TypeColorTag.GREY,
		},
		paymentSystems: {
			title: t('bookies.paymentSystems'),
			list: bookieBio?.paymentSystems,
			isSpoiler: true,
			tagColorType: TypeColorTag.GREY,
		},
		foundationYear: {
			title: t('bookies.foundationYear'),
			list: [bookieBio?.foundationYear],
			isSpoiler: false,
			tagColorType: TypeColorTag.GREY,
		},
		owners: {
			title: t('bookies.owner'),
			item: bookieBio?.owner,
		},
		siteLanguages: {
			withPrompts: true,
			title: t('bookies.siteLanguages'),
			list: bookieBio?.siteLanguages,
			isSpoiler: bookieBio?.siteLanguages?.length > 4,
			tagColorType: TypeColorTag.GREY,
		},
		license: {
			title: t('bookies.license'),
			item: dataByGeo?.license,
		},
		licenseCountries: {
			withPrompts: true,
			title: t('bookies.licenseCountries'),
			list: bookieBio?.licenceCountries,
			isSpoiler: bookieBio?.licenceCountries?.length > 1,
			tagColorType: TypeColorTag.GREY,
		},
		address: {
			title: t('bookies.address'),
			item: dataByGeo?.address,
		},
		bannedCountries: {
			withPrompts: true,
			title: t('bookies.bannedCountries'),
			list: bookieBio?.bannedCountries,
			isSpoiler: bookieBio?.bannedCountries?.length > 1,
			tagColorType: TypeColorTag.GREY,
		},
		products: {
			title: t('bookies.products'),
			list: bookieBio?.products,
			isSpoiler: bookieBio?.products?.length > 1,
			tagColorType: TypeColorTag.BLUE,
		},
	};
};

interface IgetBookieReviewTabs {
	t: any;
	hru: string;
	rootLink: string;
	existBonuses: boolean;
	existFaqs: boolean;
	locale?: string;
	existContentList: boolean;
	betPlatformType: BetPlatformType;
}

export const getBookieReviewTabs = ({
	t,
	hru,
	locale,
	rootLink,
	existFaqs,
	existBonuses,
	existContentList,
	betPlatformType,
}: IgetBookieReviewTabs) => {
	const tabs = [];
	tabs.push({
		id: 'review',
		title: t('bookies.review'),
		dataTest: 'review-tab',
		link: `${rootLink}/${hru}`,
	});
	if (existContentList) {
		tabs.push({
			id: 'news',
			title: t('tags.news'),
			dataTest: 'news-tab',
			link: `${rootLink}/${hru}/${SPORT_NEWS_PAGE_URLS?.[locale]}`,
		});
	}
	let reviewsPageTitle = t('bookies.customerReviews');

	if (locale === LANG.en) {
		if (betPlatformType === BetPlatformType.Bookmaker) {
			reviewsPageTitle = t('bookies.customerReviewsBookmaker');
		} else {
			reviewsPageTitle = t('bookies.customerReviewsCasino');
		}
	}

	tabs.push({
		id: 'reviews',
		title: reviewsPageTitle,
		dataTest: 'review-tab',
		link: `${rootLink}/${hru}/${reviewsCustomUrls?.[locale]}`,
	});
	if (existBonuses) {
		tabs.push({
			id: 'allBonuses',
			title: t('bookies.bonuses'),
			dataTest: 'review-tab',
			link: `${rootLink}/${hru}/#allBonuses`,
		});
	}
	if (existFaqs) {
		tabs.push({
			id: 'FAQ',
			title: 'FAQ',
			dataTest: 'review-tab',
			link: `${rootLink}/${hru}/#FAQ`,
		});
	}
	return tabs;
};

interface IBookieRatingTabs {
	t: any;
	locale: string;
	bookieRatingListing?: BookieRatingItem[];
	casinoRatingListing?: CasinoRatingItem[];
}

export const getBookieRatingTabs = ({
	t,
	locale,
	bookieRatingListing,
	casinoRatingListing,
}: IBookieRatingTabs) => {
	return [
		{
			id: 'bookmakers',
			title: t('translations.bookmakers'),
			link: `${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieDefRatingUrls?.[locale]}`,
			dataTest: 'bookie-header-tab',
			...addIfObj(!!bookieRatingListing?.length, {
				section: {
					isActive: false,
					isOpened: false,
					link: '',
					name: t('translations.bookmakers'),
					children: bookieRatingListing?.map(({ hru, name }) => ({
						name,
						link: `/${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieCustomRatingUrls?.[locale]}/${hru}/`,
					})),
				},
			}),
		},
		{
			id: 'casino',
			title: t('bookies.casino'),
			dataTest: 'bookie-header-tab',
			link: `${locale}/casino/${casinoDefRatingUrls?.[locale]}`,
			...addIfObj(!!casinoRatingListing?.length, {
				section: {
					isActive: false,
					isOpened: false,
					link: '',
					name: t('bookies.casino'),
					children: casinoRatingListing?.map(({ hru, name }) => ({
						name,
						link: `/${locale}/casino/${casinoCustomRatingUrls?.[locale]}/${hru}/`,
					})),
				},
			}),
		},
	];
};

export enum SortRatingType {
	BY_RATING = 'byRating',
	BY_BONUS = 'byBonus',
}

export const sortingTabs = (t: any): DropdownItem[] => [
	{
		id: SortRatingType.BY_RATING,
		text: t('bookies.byRating'),
	},
	{
		id: SortRatingType.BY_BONUS,
		text: t('bookies.byBonus'),
	},
];

export const parseCasinoBonus = (bonus: string, t: any) => {
	const amountRegex = /(\d+(\s\d{3})*)/;
	const amountMatch = bonus?.match(amountRegex);
	const amount = amountMatch ? parseInt(amountMatch[1]?.replace(/\s/g, '')) : 0;
	const separatedBonus = bonus?.split('+');
	const freeSpins = parseInt(
		separatedBonus?.find(el => el?.includes(t('bookies.freeSpins'))),
	);

	return { amount, freeSpins };
};

interface ISortByRating {
	bookies?: (Bookie | Casino)[];
	gradeType: keyof (BookieGrades & CasinoGrades);
}

export const sortByRating = ({ bookies = [], gradeType }: ISortByRating) => {
	return bookies?.sort((a, b) => {
		const gradeA = a?.dataByGeo
			? (a?.dataByGeo?.grades?.[gradeType] as number)
			: (a?.grades?.[gradeType] as number);
		const gradeB = b?.dataByGeo
			? (b?.dataByGeo?.grades?.[gradeType] as number)
			: (b?.grades?.[gradeType] as number);

		return gradeB - gradeA;
	});
};

interface ISortCasinoByBonus {
	t: any;
	bookies: Casino[];
}

export const sortCasinoByBonus = ({ bookies, t }: ISortCasinoByBonus) => {
	return bookies?.sort((a, b) => {
		const bonusA = getBonus({ bookie: a, t, isBookie: false });
		const bonusB = getBonus({ bookie: b, t, isBookie: false });
		const parsedBonusA = parseCasinoBonus(bonusA?.text, t);
		const parsedBonusB = parseCasinoBonus(bonusB?.text, t);

		if (parsedBonusA?.amount !== parsedBonusB?.amount) {
			return parsedBonusB?.amount - parsedBonusA?.amount;
		} else {
			return parsedBonusB?.freeSpins - parsedBonusA?.freeSpins;
		}
	});
};

export const parseBookmakerBonus = (bonus: string) => {
	const amountRegex = /(\d+(\s\d{3})*)/;
	const amountMatch = bonus?.match(amountRegex);
	return amountMatch ? parseInt(amountMatch[1]?.replace(/\s/g, '')) : 0;
};

interface ISortBookmakerByBonus {
	t: any;
	bookies: Bookie[];
}

export const sortBookmakerByBonus = ({ bookies, t }: ISortBookmakerByBonus) => {
	return bookies?.sort((a, b) => {
		const bonusA = getBonus({ bookie: a, t, isBookie: true });
		const bonusB = getBonus({ bookie: b, t, isBookie: true });
		return (
			parseBookmakerBonus(bonusB?.text) - parseBookmakerBonus(bonusA?.text)
		);
	});
};

interface IGetRbWidgetData {
	locale: string;
	countryCode?: string;
	showBookie?: boolean;
	showCasino?: boolean;
}

export const getRbWidgetData = async ({
	locale,
	showBookie,
	showCasino,
	countryCode = 'DE',
}: IGetRbWidgetData): Promise<RbWidgetData> => {
	const listOfPromises = await Promise.allSettled([
		showBookie ? getBookieWidgetData({ locale, countryCode }) : null,
		showCasino ? getCasinoWidgetData({ locale, countryCode }) : null,
	]);

	const [bookieRating, casinoRating] = listOfPromises?.map(
		promise => promise?.['value'],
	) as [BookieRating, CasinoRating];

	return {
		bookies: showBookie
			? [
					...(bookieRating?.topBookies || []),
					...(bookieRating?.otherBookies || []),
				]
			: ([] as Bookie[]),
		casinos: showCasino
			? [
					...(casinoRating?.topCasinos || []),
					...(casinoRating?.otherCasinos || []),
				]
			: ([] as Casino[]),
		totalBookieCount: bookieRating?.totalBookiesCount,
		totalCasinoCount: casinoRating?.totalCasinosCount,
	};
};

const extractLinks = ({
	items,
	rootLink,
	locale,
}): { link: string; text: string }[] =>
	items?.reduce((acc, item) => {
		const isReviewExist = item?.existingTranslations?.includes(
			locale?.toUpperCase(),
		);
		if (isReviewExist) {
			acc.push({
				link: `${rootLink}/${item?.hru}/`,
				text: item?.name,
			});
		}
		return acc;
	}, []);

export type IRbWidgetLinks = { link: string; text: string }[];

export const getRbWidgetBookieSSR = async ({
	locale,
}: {
	locale: string;
}): Promise<IRbWidgetLinks> => {
	const bookies = await getBookieWidgetLinks({ locale });

	const bookieLinks = extractLinks({
		locale,
		items: bookies,
		rootLink: `${TRIBUNA_COM_URL}${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieDefRatingUrls?.[locale]}`,
	});

	return bookieLinks;
};

export const getRbWidgetCasinoSSR = async ({
	locale,
}: {
	locale: string;
}): Promise<IRbWidgetLinks> => {
	const casinos = await getCasinoWidgetLinks({ locale });

	const casinoLinks = extractLinks({
		locale,
		items: casinos,
		rootLink: `${TRIBUNA_COM_URL}${locale}/casino/${casinoDefRatingUrls?.[locale]}`,
	});

	return casinoLinks;
};

export const bookieDefRatingUrls = {
	en: 'sportsbook',
	es: 'casas-de-apuestas',
	fr: 'bookmakers',
	de: 'buchmacher',
	it: 'bookmaker',
	ar: 'sportsbook',
};

export const casinoDefRatingUrls = {
	en: 'casino-reviews',
	es: 'resenas-de-casinos',
	fr: 'revues-des-casinos',
	de: 'casino-testberichte',
	it: 'recensioni-di-casino',
	ar: 'casino-sites',
};

export const casinoCustomRatingUrls = {
	en: 'ratings',
	es: 'clasificaciones',
	fr: 'notations',
	de: 'bewertungen',
	it: 'valutazioni',
	ar: 'ratings',
};

export const bookieCustomRatingUrls = {
	en: 'betting-sites',
	es: 'sitios-de-apuestas',
	fr: 'sites-de-paris-sportifs',
	de: 'wettanbieter',
	it: 'siti-scommesse',
	ar: 'betting-sites',
};

export const reviewsCustomUrls = {
	en: 'reviews',
	es: 'opiniones',
	fr: 'avis-des-clients',
	de: 'erfahrungen',
	it: 'recensioni-degli-utenti',
	ar: 'reviews',
};

interface IBookieReviewJsonLd {
	locale: string;
	isCasino: boolean;
	bookie: Bookie | Casino;
	defaultDescription: string;
}

export const getBookieReviewJsonLd = ({
	isCasino,
	locale,
	bookie,
	defaultDescription,
}: IBookieReviewJsonLd) => {
	const hru = bookie?.hru;
	const name = bookie?.name;
	const dataByGeo = bookie?.dataByGeo;
	const defaultH1 = isCasino ? `${name} CASINO` : name;
	const bookieH1 = dataByGeo?.seo?.h1 || defaultH1;
	const pros = bookie?.pros?.split(';');
	const cons = bookie?.cons?.split(';');
	const grades = dataByGeo
		? { ...dataByGeo?.reducedGrades }
		: { ...bookie?.reducedGrades };
	const gradeValue = grades?.tribuna;
	const url = isCasino
		? `https://tribuna.com/${locale}/casino/${casinoDefRatingUrls?.[locale]}/${hru}/`
		: `https://tribuna.com/${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieDefRatingUrls?.[locale]}/${hru}/`;

	return {
		'@context': 'http://schema.org',
		'@type': 'Review',
		name: bookieH1,
		description: dataByGeo?.seo?.description || defaultDescription,
		url,
		image: bookie?.logoURL,
		datePublished: dayjs
			.tz(bookie?.publicationTime * 1000)
			.format('YYYY-MM-DD'),
		itemReviewed: {
			'@type': 'Organization',
			name: bookieH1,
			image: bookie?.logoURL,
		},
		author: {
			'@type': 'Organization',
			name: 'Tribuna.com',
			url: 'https://tribuna.com/',
		},
		...addIfObj(!!pros?.length, {
			positiveNotes: {
				'@type': 'ItemList',
				itemListElement: pros?.map((el, index) => [
					{
						'@type': 'ListItem',
						position: index + 1,
						name: el,
					},
				]),
			},
		}),
		...addIfObj(!!cons?.length, {
			negativeNotes: {
				'@type': 'ItemList',
				itemListElement: cons?.map((el, index) => [
					{
						'@type': 'ListItem',
						position: index + 1,
						name: el,
					},
				]),
			},
		}),
		reviewRating: {
			'@type': 'Rating',
			worstRating: 0,
			bestRating: 5,
			ratingValue: Number.isInteger(gradeValue)
				? gradeValue.toString() + '.0'
				: gradeValue,
		},
	};
};

interface IBookieReviewsListJsonLd {
	locale: string;
	isCasino: boolean;
	bookie: Bookie | Casino;
	reviews: BetReview[];
	formattedDate: string;
	t: any;
}
export const getBookieReviewsListJsonLd = ({
	isCasino,
	locale,
	bookie,
	reviews,
	formattedDate,
	t,
}: IBookieReviewsListJsonLd) => {
	const hru = bookie?.hru;
	const name = bookie?.name;
	const dataByGeo = bookie?.dataByGeo;
	const defaultH1 = isCasino ? `${name} CASINO` : name;
	const bookieH1 = dataByGeo?.seo?.h1 || defaultH1;
	const reducedGrades = dataByGeo
		? { ...dataByGeo?.reducedGrades }
		: { ...bookie?.reducedGrades };
	const grades = dataByGeo ? { ...dataByGeo?.grades } : { ...bookie?.grades };
	const reducedGradeValue = reducedGrades?.tribuna;
	const gradeValue = grades?.tribuna;
	const url = isCasino
		? `https://tribuna.com/${locale}/casino/${casinoDefRatingUrls?.[locale]}/${hru}/`
		: `https://tribuna.com/${locale}/${BETTING_SPORT_URLS?.[locale]}/${bookieDefRatingUrls?.[locale]}/${hru}/`;

	const transformedReviews = reviews?.map(review => ({
		'@type': 'Review',
		name: t('bookies.review-seo-name', {
			userName: preventXss(review?.user?.name),
			date: formattedDate,
		}),
		datePublished: new Date(review?.created * 1000).toISOString(),
		reviewBody: preventXss(review?.text),
		reviewRating: {
			'@type': 'Rating',
			bestRating: 5,
			ratingValue: review?.score,
			worstRating: 1,
		},
		author: {
			'@type': 'Person',
			name: preventXss(review?.user?.name),
			url: `https://tribuna.com/${locale}/profile/${review?.user?.id}/`,
		},
	}));

	const licence = bookie?.dataByGeo?.license; //optional, may not exist
	const foundingDate = bookie?.bio?.foundationYear;
	const address = bookie?.dataByGeo?.address; //optional, may not exist
	const mainPaymentSystems = bookie?.dataByGeo?.mainPaymentSystems; //optional, may not exist

	return {
		'@context': 'http://schema.org',
		'@type': 'Organization',
		logo: bookie?.logoURL,
		appIOSButtonURL: bookie?.appIOSButtonURL,
		appAndroidButtonURL: bookie?.appAndroidButtonURL,
		name: bookieH1,
		legalName: bookie?.bio?.owner,
		url,
		...addIfObj(!!licence, { licence }),
		...addIfObj(!!foundingDate, { foundingDate }),
		...addIfObj(!!address, { address }),
		...addIfObj(!!mainPaymentSystems, { mainPaymentSystems }),
		platforms: bookie?.bio?.platforms,
		aggregateRating: {
			'@type': 'AggregateRating',
			ratingCount: gradeValue,
			ratingValue: reducedGradeValue,
		},
		review: transformedReviews,
	};
};

export const BETTING_SPORT_URLS = {
	ru: 'betting',
	uk: 'betting',
	be: 'betting',
	'ru-BY': 'betting',
	'ru-RU': 'betting',
	en: 'betting',
	es: 'apuestas',
	fr: 'parier-en-ligne',
	de: 'wetten',
	it: 'scommesse',
	ar: 'betting',
};

interface IGetFirstBookieInRating {
	t: any;
	locale: string;
	sportId: SportId;
	countryCode: string;
}

export const getFirstBookieInRating = async ({
	t,
	locale,
	sportId,
	countryCode,
}: IGetFirstBookieInRating) => {
	const isBetting = sportId === SportId.Bet;
	const rating = await (isBetting ? getBookieWidgetData : getCasinoWidgetData)({
		locale,
		countryCode,
		limit: 1,
	});

	const bookie = isBetting
		? rating?.topBookies?.[0] || rating?.otherBookies?.[0]
		: rating?.topCasinos?.[0] || rating?.otherCasinos?.[0];

	const bonus = bookie?.dataByGeo?.bonuses?.[0];
	if (bonus) {
		bookie.bonus = { ...bonus, text: getBonusText(bonus) };
	}
	return bookie;
};

interface IGetIgamingStickyButton {
	t: any;
	locale: string;
	sportId: SportId;
	content: News | Post;
}

export const getIgamingStickyButton = async ({
	t,
	locale,
	content,
	sportId,
}: IGetIgamingStickyButton) => {
	const countryCode = await getCoutryCode();
	const iGamingTag = content?.tags?.find(
		({ type }) => type === TagType.Bookie || type === TagType.Casino,
	);
	if (iGamingTag) {
		const { bookie } = await (
			iGamingTag?.type === TagType.Bookie
				? getBookieStickyButton
				: getCasinoStickyButton
		)({ id: iGamingTag?.hru, locale, countryCode });

		if (!bookie?.isBanned) {
			const bonus = bookie?.dataByGeo?.bonuses?.[0];
			if (bonus) {
				bookie.bonus = { ...bonus, text: getBonusText(bonus) };
			}
			return bookie;
		}
	}

	return getFirstBookieInRating({ countryCode, sportId, locale, t });
};
