import {
	createLocalizedPathnamesNavigation,
	Pathnames,
} from 'next-intl/navigation';

export const locales = ['en', 'fr', 'de', 'it', 'es', 'ar'];
export const localePrefix = 'always'; // Default

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
	// If all locales use the same pathname, a
	// single external path can be provided.
	'/': '/',
	'/blog': '/blog',
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter } =
	createLocalizedPathnamesNavigation({ locales, localePrefix, pathnames });
