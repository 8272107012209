import { createContext, useContext } from 'react';

import useFirebaseAuth, { IFireBaseUser } from '@hooks/useFirebaseAuth';

interface IAuthContextInterface {
	authUser: IFireBaseUser;
	loading: boolean;
	//TODO: remove any
	signInWithEmailAndPassword: any;
	createUserWithEmailAndPassword: any;
	sendPasswordResetEmail: any;
	signOut: any;
	sendEmailVerificationHandler: any;
}

const authUserContext = createContext({
	authUser: null,
	loading: true,
	signInWithEmailAndPassword: async (email, password) => ({ email, password }),
	createUserWithEmailAndPassword: async (email, password) => ({
		email,
		password,
	}),
	sendPasswordResetEmail: async email => ({ email }),
	signOut: async () => {},
	sendEmailVerificationHandler: () => {},
});

export default function AuthUserProvider({ children }) {
	const auth: IAuthContextInterface = useFirebaseAuth();
	return (
		<authUserContext.Provider value={auth}>{children}</authUserContext.Provider>
	);
}

export const useAuth = () => useContext(authUserContext);
