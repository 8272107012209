import { gql } from '@apollo/client';

// localizedString for new API
export const LOCALIZED_STRING_FRAGMENT = gql`
	fragment localizedString on LocalizedString {
		en
		es
		it
		de
		fr
		ar
	}
`;

// localizedString for old API
export const LOCALIZED_STRING_VALUE_FRAGMENT = gql`
	fragment localizedStringValue on LocalizedStringValue {
		EN
		ES
		DE
		FR
		IT
		AR
	}
`;

// localizedString for bookie
export const MULTI_LANG_STRING = gql`
	fragment multiLangString on MultiLangString {
		en
		it
		es
		fr
		de
		ar
	}
`;

export const STRUCTURED_BODY_BOOKIE = gql`
	fragment StructuredBodyFragmentForBookie on BodyTopElement {
		type
		value {
			... on BodyParagraph {
				platforms
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
								color
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
								color
							}
						}
					}
				}
			}
			... on BodySubtitle {
				platforms
				text
				level
			}
			... on BodyLinkedImage {
				href
				image {
					source
					width
					height
					text
				}
			}
			... on BodyImage {
				source
				width
				height
				text
			}
			... on BodyTwitter {
				url
				id
			}
			... on BodyInstagram {
				url
				id
			}
			... on BodyIframe {
				source
				width
				height
			}
			... on BodyYoutube {
				source
				id
				platforms
			}
			... on BodyAd {
				size
				name
				platforms
			}
			... on BodyList {
				style
				items {
					elements {
						type
						value {
							... on BodyText {
								text
								format {
									bold
									italic
									color
								}
							}
							... on BodyLink {
								text
								href
								format {
									bold
									italic
									color
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const STRUCTURED_BODY_UNIFICATION = gql`
	fragment StructuredBodyFragmentUnification on BodyTopElement {
		type
		value {
			... on BodyParagraph {
				platforms
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
								color
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
								color
							}
						}
					}
				}
			}
			... on BodySubtitle {
				platforms
				text
				level
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
							}
						}
					}
				}
			}
			... on BodyLinkedImage {
				href
				image {
					source
					width
					height
					text
				}
			}
			... on BodyImage {
				source
				width
				height
				text
			}
			... on BodyTwitter {
				url
				id
			}
			... on BodyInstagram {
				url
				id
			}
			... on BodyIframe {
				source
				width
				height
			}
			... on BodyYoutube {
				source
				id
				start
				end
			}
			... on BodyAd {
				size
				name
				platforms
			}
			... on BodyList {
				style
				items {
					elements {
						type
						value {
							... on BodyText {
								text
								format {
									bold
									italic
									color
								}
							}
							... on BodyLink {
								text
								href
								format {
									bold
									italic
									color
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const SHORT_STRUCTURED_BODY = gql`
	fragment ShortStructuredBodyFragment on BodyTopElement {
		type
		value {
			... on BodyList {
				style
				items {
					elements {
						type
						value {
							... on BodyText {
								text
								format {
									bold
									italic
									color
								}
							}
							... on BodyLink {
								text
								href
								format {
									bold
									italic
									color
								}
							}
						}
					}
				}
			}
			... on BodyParagraph {
				platforms
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
								color
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
								color
							}
						}
					}
				}
			}
			... on BodySubtitle {
				platforms
				text
				level
				elements {
					type
					value {
						... on BodyText {
							text
							format {
								bold
								italic
							}
						}
						... on BodyLink {
							text
							href
							format {
								bold
								italic
							}
						}
					}
				}
			}
		}
	}
`;

export const LOCALIZED_SB_FRAGMENT = gql`
	${STRUCTURED_BODY_UNIFICATION}
	fragment LocalizedSB on LocalizedSB {
		en {
			...StructuredBodyFragmentUnification
		}
		es {
			...StructuredBodyFragmentUnification
		}
		de {
			...StructuredBodyFragmentUnification
		}
		fr {
			...StructuredBodyFragmentUnification
		}
		it {
			...StructuredBodyFragmentUnification
		}
		ar {
			...StructuredBodyFragmentUnification
		}
	}
`;

export const LOCALIZED_SHORT_SB_FRAGMENT = gql`
	${SHORT_STRUCTURED_BODY}
	fragment LocalizedShortSB on LocalizedSB {
		en {
			...ShortStructuredBodyFragment
		}
		es {
			...ShortStructuredBodyFragment
		}
		de {
			...ShortStructuredBodyFragment
		}
		fr {
			...ShortStructuredBodyFragment
		}
		it {
			...ShortStructuredBodyFragment
		}
		ar {
			...ShortStructuredBodyFragment
		}
	}
`;

export const TAG_STAT_MATCH_FRAGMENT = gql`
	fragment statMatchForTag on statMatch {
		id
		hru
		links {
			link
		}
		matchStatus
		periodId
		scheduledAt
		hadPenalties
		home {
			team {
				id
				url
				tag {
					hru
				}
				name
				logo {
					main
					resize(width: "24", height: "24")
					webp(width: "60", height: "60", quality: 100)
				}
			}
			score
			penaltyScore
		}
		away {
			team {
				id
				url
				tag {
					hru
				}
				name
				logo {
					main
					resize(width: "24", height: "24")
					webp(width: "60", height: "60", quality: 100)
				}
			}
			score
			penaltyScore
		}
		winner
		winnerTeam {
			team {
				id
			}
		}
		currentMinute
		currentMinuteMain
		currentMinuteOver
		dateOnly
		id
		roundName
		scheduledAt
		url
		season {
			tournament {
				id
				name
				url
			}
		}
		bettingOdds {
			hxa {
				h
				x
				a
			}
		}
	}
`;

export const TAGS_QUERIES_ARTICLE = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment TagsQueryFragmentForArticle on Tag {
		id
		title {
			...localizedString
		}
		hru
		type
		logo {
			url
		}
		extra {
			... on TagTeamExtra {
				type
			}
		}
		sport {
			path
			id
		}
		statObject {
			... on statPlayer {
				id
				tag {
					hru
				}
				name: coalesceName(names: ["name", "lastName", "firstName"])
				coverage {
					isCovered: career
				}
				avatar {
					webp(width: "120", height: "120")
				}
			}
			... on statTeam {
				id
				tag {
					hru
				}
				name
				coverage {
					roster
					matches
					isCovered: matches
				}
				logo {
					webp(width: "120", height: "120")
				}
			}
			... on statTournament {
				id
				tag {
					hru
				}
				name
				coverage {
					isCovered: season
				}
				logo {
					webp(width: "120", height: "120")
				}
			}
		}
	}
`;

const BLOG_POST_DESCRIPTION_FRAGMENT = gql`
	fragment blogPostDescription on BodyTopElement {
		value {
			... on BodyParagraph {
				elements {
					value {
						... on BodyText {
							text
						}
					}
				}
			}
		}
	}
`;

export const LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT = gql`
	${BLOG_POST_DESCRIPTION_FRAGMENT}
	fragment localizedBlogPostDescription on LocalizedSB {
		en {
			...blogPostDescription
		}
		es {
			...blogPostDescription
		}
		de {
			...blogPostDescription
		}
		it {
			...blogPostDescription
		}
		fr {
			...blogPostDescription
		}
		ar {
			...blogPostDescription
		}
	}
`;

export const PERSON_CAREER_INFO = gql`
	fragment careerTeamInfo on statTeam {
		id
		name
		picture(productType: TRIBUNA, format: LOGO) {
			webp(width: "40", height: "40", quality: 100)
		}
		url
	}

	fragment careerSeasonInfo on statPlayerCareer {
		season {
			id
			name
			tournament {
				id
				name
				url
				tag {
					hru
				}
				picture(productType: TRIBUNA, format: LOGO) {
					webp(width: "40", height: "40", quality: 100)
				}
			}
		}
	}

	fragment playerCareerData on statCareer {
		active
		startDate
		endDate
		statBySeasonName {
			seasonName
			stat {
				cleanSheet
				goalsConceded
				matchesPlayed
				goalsScored
				assists
			}
			items {
				cleanSheet
				goalsConceded
				matchesPlayed
				goalsScored
				assists
				...careerSeasonInfo
			}
		}
	}

	fragment coachCareerData on statCareer {
		active
		startDate
		endDate
		role
		statBySeasonName {
			seasonName
			stat {
				TotalGamesWonCoach
				TotalGamesLostCoach
				TotalGamesDrawCoach
			}
			items {
				TotalGamesWonCoach
				TotalGamesLostCoach
				TotalGamesDrawCoach
				...careerSeasonInfo
			}
		}
	}
`;

export const RANKING_TEAM_STAT_FRAGMENT = gql`
	fragment rankingTeamStatFragment on statRankingTeamStat {
		attribute
		items {
			value
			rank
			team {
				id
				name
				url
				logo {
					webp(height: "40", width: "40", quality: 80)
				}
			}
		}
	}
`;

export const BEST_TEAMS_IN_SEASON_FRAGMENT = gql`
	${RANKING_TEAM_STAT_FRAGMENT}
	fragment bestTeamsInSeasonFragment on statSeason {
		rankingTeamStat(
			input: {
				limit: 3
				attribute: [
					TOTAL_GOALS
					TOTAL_YELLOW_CARD
					TOTAL_RED_CARD
					TOTAL_GOAL_CONVERSION
					TOTAL_CLEAN_SHEET
					TOTAL_PASS_PCT
					TOTAL_SCORING_ATT
					TOTAL_ATTEMPTS_CONCEDED
					TOTAL_GOALS_CONCEDED
					TOTAL_WON_CORNERS
					TOTAL_LOST_CORNERS
					TOTAL_GOALS_CONCEDED_OBOX
					TOTAL_OFFSIDE
					TOTAL_FOULS
					TOTAL_PASS
					TOTAL_WAS_FOULED
					TOTAL_CROSS
					TOTAL_TOUCHES_IN_OPPOSITION_BOX
				]
			}
		) {
			...rankingTeamStatFragment
		}
	}
`;

export const RANKING_PLAYER_STAT_FRAGMENT = gql`
	fragment RankingPlayerStatFragment on statRankingPlayerStat {
		items {
			value
			rank
			player {
				id
				firstName
				lastName
				url
				avatar {
					webp(height: "40", width: "40", quality: 80)
				}
				nationality {
					picture(productType: TRIBUNA, format: PNG) {
						webp(height: "40", width: "40", quality: 80)
					}
				}
			}
			team {
				name
				url
				logo {
					webp(height: "40", width: "40", quality: 80)
				}
			}
		}
		attribute
	}
`;

export const BEST_IN_TEAM_SEASON_PAGE_FRAGMENT = gql`
	${RANKING_PLAYER_STAT_FRAGMENT}
	fragment BestInSeasonPageFragment on statSeason {
		shortName
		rankingPlayerStat(
			input: {
				teams: $teamID
				limit: 3
				attribute: [
					TOTAL_GOALS
					TOTAL_ASSISTS
					GOALS_AND_ASSISTS
					TOTAL_YELLOW_CARD
					TOTAL_RED_CARD
					TOTAL_ATT_ASSIST
					TOTAL_MINS_PLAYED
					TOTAL_ATT_PEN_GOAL
					TOTAL_ATT_FREEKICK_GOAL
					MINUTES_PLAYED_PER_GOAL
					TOTAL_ATTEMPT
					TOTAL_WON_CONTEST
					ACCURATE_PASSES_PCT
					TOTAL_ACCURATE_CROSS
					TOTAL_FWD_ZONE_PASS
					TOTAL_WON_TACKLE
					TOTAL_CHALLENGE_LOST
					TOTAL_DUELS_WON
					TOTAL_DUELS_LOST
					TOTAL_ACCURATE_LONG_BALLS
					TOTAL_WAS_FOULED
					TOTAL_FOULS
					TOTAL_OFFSIDE
					TOTAL_SUB_ON
					TOTAL_AERIAL_WON
					TOTAL_INTERCEPTION
					TOTAL_CLEARANCE
				]
			}
		) {
			...RankingPlayerStatFragment
		}
		goalkeeperPlayerStat: rankingPlayerStat(
			input: {
				teams: $teamID
				limit: 3
				attribute: [
					TOTAL_CLEAN_SHEET
					TOTAL_GOALS_CONCEDED
					TOTAL_SAVES
					TOTAL_GOOD_HIGH_CLAIM
				]
				amplua: [GOALKEEPER]
			}
		) {
			...RankingPlayerStatFragment
		}
	}
`;

export const AUTHOR_FRAGMENT = gql`
	fragment Author on Author {
		user {
			id
			name
			nickname
			avatar {
				url
			}
		}
	}
`;
