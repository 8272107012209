import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

// Factory function for creating a Zustand store with immer and devtools middleware
export const createImmerSlice = <T extends object>(
	config: (set: any, get: any) => T,
) => {
	const createStore = () => create<T>()(devtools(immer(config)));
	return createStore();
};
