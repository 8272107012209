import { useEffect, useState } from 'react';

import { FirebaseErrors } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
	sendEmailVerification,
} from 'firebase/auth';
import { useTranslations } from 'next-intl';
import { toast } from 'react-hot-toast';

import { useUserStore } from '@store/user';
import { auth } from '@utils/firebaseInit';
import { sendAnalytics } from '@utils/helpers';

export interface IFireBaseUser {
	uid: string | number;
	email: string;
}

const formatAuthUser = user => ({
	uid: user.uid,
	email: user.email,
});

export default function useFirebaseAuth() {
	const t = useTranslations();
	const { closeForgotPasswordModal, setError } = useUserStore(state => state);
	const [authUser, setAuthUser] = useState<IFireBaseUser>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const authStateChanged = async authState => {
		if (!authState) {
			setLoading(false);
			return;
		}

		setLoading(true);

		const formattedUser = formatAuthUser(authState);

		setAuthUser(formattedUser);

		setLoading(false);
	};

	const clear = () => {
		setAuthUser(null);
		setLoading(true);
	};

	const signInWithEmailAndPasswordHOF = (email, password) =>
		signInWithEmailAndPassword(auth, email, password);

	const createUserWithEmailAndPasswordHOF = (email, password) => {
		return createUserWithEmailAndPassword(auth, email, password).then(
			async authUser => {
				try {
					return authUser;
				} catch (error) {
					toast.error(error?.message);
				}
			},
		);
	};

	const sendEmailVerificationHandler = () => {
		onAuthStateChanged(auth, user => {
			sendEmailVerification(user, { url: `${window.location.href}#loggedin` })
				.then(() => {
					toast.success(t('translations.email-sent'));
				})
				.catch(error => {
					setError(FirebaseErrors.EmailTooManyRequests);
					toast.error(error?.message);
				});
		});
	};

	const sendPasswordResetEmailHOF = async email => {
		try {
			await sendPasswordResetEmail(auth, email);

			toast.success(t('translations.password-reseted'));
			closeForgotPasswordModal();
			sendAnalytics({
				category: 'password',
				name: 'success',
				label: 'reset',
			});
		} catch (err) {
			toast.error(t('translations.incorrect-email'));
			sendAnalytics({
				category: 'password',
				name: 'not_found',
				label: 'reset',
			});
		}
	};

	const signOutHOF = () => signOut(auth).then(clear);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, authStateChanged);
		return () => unsubscribe();
	}, []);

	return {
		authUser,
		loading,
		signInWithEmailAndPassword: signInWithEmailAndPasswordHOF,
		createUserWithEmailAndPassword: createUserWithEmailAndPasswordHOF,
		sendPasswordResetEmail: sendPasswordResetEmailHOF,
		signOut: signOutHOF,
		sendEmailVerificationHandler,
	};
}
