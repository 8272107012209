'use client';
import React, { useEffect, useRef, useState } from 'react';

import { FooterBlock } from '@ui-kit/main-api-types';
import { SportProvider } from '@ui-kit/main-frontend-ui-kit/dist/src/context/SportContext';
import { FCWithChildren } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { usePathname, useParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';

import styles from './Main.module.scss';

import AuthUserProvider from '@context/AuthUserContext';
import { BETTING_SPORT_URLS } from '@utils/bookie';
import { nativeAds } from '@utils/constants';
import { clubs, getAppnameFromNewsHru } from '@utils/helpers';
import { isItBotCheck, isMobileDeviceMDNCheck } from '@utils/tools';
import { INativeAdsConfigItem } from '@utils/types';

const Header = dynamic(() => import('@components/Header_new'), {
	ssr: true,
});
const Footer = dynamic(() => import('@components/Footer'), {
	ssr: true,
});
const AuthModals = dynamic(() => import('@components/AuthModals'), {
	ssr: false,
});
const NativeAdsMobileBranding = dynamic(
	() => import('@components/Native-ads-mobile'),
	{
		ssr: false,
	},
);
const DugoutPlayer = dynamic(() => import('@components/Dugout-player'), {
	ssr: false,
});
const Banner = dynamic(() => import('@components/Banner-alternative'), {
	ssr: false,
});
const BannerCatfish = dynamic(() => import('@components/Banner-catfish'), {
	ssr: false,
});
const Fullscreen = dynamic(() => import('@components/Banner-fullscreen'), {
	ssr: false,
});
const MobileFullScreen = dynamic(
	() => import('@components/Mobile-full-screen'),
	{
		ssr: false,
	},
);

const BannerAfterHeader = dynamic(
	() => import('@components/Banner-after-header'),
	{
		ssr: false,
	},
);
const NoSsr = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/NoSSRHOC').then(
			m => m.NoSsr,
		),
	{
		ssr: false,
	},
);

const DEFAULT_TITLE = 'Tribuna.com';

interface IMainLayoutProps {
	className?: string;
	footerLinks: FooterBlock[];
}

export const MainLayout: FCWithChildren<IMainLayoutProps> = ({
	children,
	className = '',
	footerLinks,
}) => {
	const pathname = usePathname();
	const { route } = useRouter();
	const isItMatchCenterPage = route?.includes('matches');
	const isItAppSubscriptionPage = route?.includes('subscription');
	const params = useParams();
	const query = k => params?.[k];
	const isItForSomeKindOfMagic = useRef(pathname === '/something-went-wrong');
	const contentWidth = useRef(0);
	const isMobile = isMobileDeviceMDNCheck();
	const isItBot = isItBotCheck();
	const isDesktop = !isMobile;
	console.info({ isMobile });
	const setContentWidth = width => {
		contentWidth.current = width;
	};
	const hasFullScreenShowed =
		typeof window !== 'undefined' &&
		(!!sessionStorage.getItem('tribuna:mobile-full-screen__showed') || false);

	const [afterHeaderBannerData, setAfterHeaderBannerData] = useState(null);
	const contentRef = useRef(null);
	useEffect(() => {
		setContentWidth(contentRef.current?.clientWidth);
	}, [contentRef.current]);

	const newsHru: any = query('id'); // Убрать any
	const isMounted = useRef(false);
	const setPageMountStatus = mountStatus => {
		isMounted.current = mountStatus;
	};

	useEffect(() => {
		setPageMountStatus(true);
	}, [pathname]);

	const appnameFromHru = getAppnameFromNewsHru({
		newsHru,
	});
	const nativeAdsNewsConfig: INativeAdsConfigItem = nativeAds[newsHru] || {};

	const transformedNewsAppnameToHru = clubs?.[appnameFromHru]?.hru;

	const isBookmakerPages =
		Object.values(BETTING_SPORT_URLS)?.some(sportName =>
			pathname.includes(sportName),
		) || pathname.includes('casino');
	const isItMobileCreatePostPage = pathname.indexOf('/create-post-mobile') > -1;
	const isHidden = {
		footer: isItMobileCreatePostPage,
		header: isItMobileCreatePostPage,
		ads: isItMobileCreatePostPage,
	};

	useEffect(() => {
		if (isDesktop && !isBookmakerPages && !isItBot) {
			const script = document.createElement('script');
			script.src = 'https://ads.vidoomy.com/tribuna_25419.js';
			script.async = true;
			document.head.appendChild(script);
		}
	}, []);

	return (
		<ThemeProvider>
			<SportProvider>
				<Head>
					<title>{DEFAULT_TITLE}</title>
				</Head>
				<div
					className={`${className} ${styles['main-layout']}`}
					style={
						afterHeaderBannerData
							? ({
									'--background-image': `url(${
										afterHeaderBannerData?.image || ''
									})`,
							  } as React.CSSProperties)
							: null
					}
				>
					{!isHidden.ads && (
						<>
							{afterHeaderBannerData?.link && (
								<a
									className={styles['main-layout__branding']}
									href={afterHeaderBannerData?.link}
									target="_blank"
									rel="sponsored,nofollow"
								></a>
							)}
							{!isBookmakerPages &&
								!isItMatchCenterPage &&
								!isItAppSubscriptionPage &&
								isDesktop && (
									<NoSsr hideFromBot>
										<Fullscreen />
									</NoSsr>
								)}
						</>
					)}

					{!isHidden.ads ? (
						<Header menu={''} />
					) : (
						<div style={{ display: 'none' }}>
							<Header menu={''} />
						</div>
					)}

					<div
						className={clsx(styles['main-layout__wrapper'], {
							[styles['main-layout__wrapper--subscription-page']]:
								isItAppSubscriptionPage,
						})}
					>
						<main ref={contentRef} className={styles['main-layout__content']}>
							{!isHidden.ads &&
								!isBookmakerPages &&
								!isItMatchCenterPage &&
								!isItAppSubscriptionPage && (
									<NoSsr hideFromBot>
										{isDesktop && (
											<div className={styles['main-layout__empty-block']}>
												<BannerAfterHeader
													key={'90live_Desktop_Top'}
													setBannerData={setAfterHeaderBannerData}
													bannerID={'90live_Desktop_Top'}
												/>
											</div>
										)}
										<div className={styles['main-layout__fixed-banners']}>
											<BannerCatfish />
										</div>
									</NoSsr>
								)}

							{!isHidden.ads &&
								!isItMatchCenterPage &&
								!isItAppSubscriptionPage &&
								!isBookmakerPages &&
								(nativeAdsNewsConfig?.branding?.mobileBranding ? (
									<NativeAdsMobileBranding
										nativeAdsNewsConfig={nativeAdsNewsConfig}
									/>
								) : (
									<div className={styles['main-layout__dugout']}>
										<NoSsr hideFromBot>
											<DugoutPlayer
												isMobile={isMobile}
												appname={transformedNewsAppnameToHru}
											/>
										</NoSsr>
									</div>
								))}

							{children}
							<div
								id="portal-wrapper"
								className={styles['main-layout__portal']}
							></div>
						</main>
					</div>
					{!isHidden.footer && (
						<Footer
							hideFooter={isItForSomeKindOfMagic.current}
							footerLinks={footerLinks}
						/>
					)}
				</div>
				{!isBookmakerPages &&
					!isItMatchCenterPage &&
					!isItAppSubscriptionPage && (
						<NoSsr hideFromBot>
							{isDesktop ? (
								<Banner id={'90live_Seedtag'} />
							) : (
								<>
									{hasFullScreenShowed ? (
										<Banner id={'interstitial'} />
									) : (
										<MobileFullScreen />
									)}
								</>
							)}
						</NoSsr>
					)}
				<AuthUserProvider>
					<AuthModals />
				</AuthUserProvider>
			</SportProvider>
		</ThemeProvider>
	);
};

export default MainLayout;
