import fetch from 'cross-fetch';

export default function crossFetch(request, options = {}) {
	return new Promise((resolve, reject) => {
		fetch(request, options).then(resp => {
			if (resp.status === 200) {
				resp.json().then(resolve);
			} else {
				resp.text().then(reject);
			}
		}, reject);
	});
}

const DEFAULT_TIMEOUT = typeof window !== 'undefined' ? 20_000 : 10_000;

export function fetchWithTimeout(uri, options = {}, time = DEFAULT_TIMEOUT) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TimeoutError. Request timed out.'));
		}, time);
		fetch(uri, options).then(
			response => {
				clearTimeout(timer);
				resolve(response);
			},
			err => {
				clearTimeout(timer);
				reject(err);
			},
		);
	});
}
