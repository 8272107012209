import { BookieRatingItem, PriorityType } from '@ui-kit/main-api-types';

import { client } from '@apollo-client';
import { client as clientV2 } from '@apollo-client-v2';

import { bookieQueries } from './queries';

export const getBookie = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: bookieQueries.getBookie,
			variables: {
				id,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => {
			return {
				bookie: res?.data?.bookieQueries?.get,
				errors: res?.errors
					?.map(error => error?.message)
					?.reduce((acc, e) => acc + e, ''),
			};
		});
};

export const getCountryFlag = async (
	countryId,
	source = 'CODE',
): Promise<{ countryName: string; flag: string }> => {
	if (!countryId) {
		return {
			countryName: null,
			flag: null,
		};
	}

	const queryResult = await clientV2.query({
		query: bookieQueries.getCountryFlag,
		variables: {
			countryId,
			source,
		},
		errorPolicy: 'all',
	});

	const statCountry = queryResult?.data?.stat?.football?.stat_country;

	return {
		countryName: statCountry?.name || null,
		flag: statCountry?.picture?.main || null,
	};
};

export const getBookieRating = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: bookieQueries.getBookieRating,
			variables: {
				id,
				language: locale?.toUpperCase(),
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.bookieQueries?.getRating);
};

interface IGetBookieRatingListing {
	locale: string;
	countryCode?: string;
	priorityType?: PriorityType;
	limit?: number;
}

export const getBookieRatingListing = ({
	locale,
	countryCode = 'DE',
	priorityType = PriorityType.LanguagePriority,
	limit,
}: IGetBookieRatingListing): Promise<BookieRatingItem[]> =>
	clientV2
		.query({
			query: bookieQueries.getBookieRatingListing,
			variables: {
				onlyRatingsFromMenu: true,
				priorityType,
				language: locale.toUpperCase(),
				limit,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.bookieQueries?.getRatingListing);

export const getBookieWidgetLinks = ({ locale }) => {
	return clientV2
		.query({
			query: bookieQueries.getBookieWidgetLinks,
			variables: {
				id: 'sportsbook',
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': 'DE',
				},
			},
		})
		.then(res => {
			const rating = res?.data?.bookieQueries?.getRating;
			return [...rating?.topBookies, ...rating?.otherBookies];
		});
};

export const getBookieWidgetData = ({
	locale,
	countryCode = 'DE',
	limit = 5,
}) => {
	return clientV2
		.query({
			query: bookieQueries.getBookieWidgetData,
			variables: {
				id: 'sportsbook',
				limit,
				language: locale?.toUpperCase(),
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.bookieQueries?.getRating);
};

export const getBookieStickyButton = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: bookieQueries.getBookieStickyButton,
			variables: {
				id,
			},
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => {
			return {
				bookie: res?.data?.bookieQueries?.get,
				errors: res?.errors
					?.map(error => error?.message)
					?.reduce((acc, e) => acc + e, ''),
			};
		});
};
