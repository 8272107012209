import { useMemo } from 'react';

import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
	addBreadcrumb,
	captureException,
	captureMessage,
	setTag,
	startInactiveSpan,
} from '@sentry/nextjs';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { fetchWithTimeout } from '@utils/fetch';
import { ApolloErrorsEnums } from '@utils/types';

import { version } from './package.json';
const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;
let globalUri = '';
let client;
let subscriptionClient;

const cacheConfig = {
	// addTypename: false,
	typePolicies: {
		statTimelineEvent: {
			keyFields: false,
		},
		graphPair: {
			keyFields: false,
		},
	},
};

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
};

export function changeGateway({ lang = 'en', appname }) {
	const computedLang = lang.replace('default', 'en');

	let uri = appname ? `${appname}/${computedLang}/` : `${computedLang}/`;
	globalUri = uri;
	return createApolloClient({ uri, lang, appname });
}

let featureDeployHeader = null;

const addIfObj = (condition, props) => (condition ? props : {});

function createApolloClient({ uri = globalUri, lang = 'en', appname = '' }) {
	let url = process.env.NEXT_PUBLIC_TRIBUNA_GATEWAY_PATH
		? `${process.env.NEXT_PUBLIC_TRIBUNA_GATEWAY_PATH}`.replace(
				'graphql',
				'v2/graphql',
			)
		: `https://api-gateway.dev.tribuna.com/v2/graphql/${uri}`;
	featureDeployHeader =
		featureDeployHeader || process.env.NEXT_PUBLIC_FEATURE_DEPLOY;

	const projectName = 'intl-tribuna';
	const nodeVersion = process?.versions?.node;
	const userAgentData =
		typeof window === 'undefined' ? {} : window?.navigator?.userAgentData;
	const browserInfo = userAgentData?.brands?.[0] || {
		brand: typeof window === 'undefined' ? '' : window?.navigator?.userAgent,
		version: '',
	};
	const fetchUserAgent =
		typeof window === 'undefined'
			? `node${nodeVersion}/${process?.platform}`
			: `(${browserInfo.brand}${browserInfo.version}/${userAgentData?.platform}) next.js/${window.next?.version}`;
	const customUserAgent = `Web ${projectName}/${version} ${fetchUserAgent}`;

	const wsUrl =
		process.env.NEXT_PUBLIC_TRIBUNA_WEBSOCKET_PATH ||
		'wss://api-gateway.dev.tribuna.com/v2/graphql/';
	let sourceHeader = '';

	if (typeof window === 'undefined') {
		url = process.env.NEXT_PUBLIC_TRIBUNA_GATEWAY_CLUSTER_URL
			? `${process.env.NEXT_PUBLIC_TRIBUNA_GATEWAY_CLUSTER_URL}`.replace(
					'graphql',
					'v2/graphql',
				)
			: 'https://api-gateway.dev.tribuna.com/v2/graphql/';
	}

	if (typeof window !== 'undefined') {
		featureDeployHeader = featureDeployHeader || window.featureDeployHeader;
		sourceHeader = window?.location?.origin || '';
	}

	const httpLink = new HttpLink({
		uri: url, // Server URL (must be absolute)
		// fetchOptions: { signal: AbortSignal.timeout(DEFAULT_TIMEOUT) },
		fetch: fetchWithTimeout,
		headers: {
			...addIfObj(!!featureDeployHeader, {
				'X-Feature-Deploy': featureDeployHeader,
			}),
			...addIfObj(!!sourceHeader, {
				'X-Source': sourceHeader,
			}),
			...addIfObj(!!customUserAgent, {
				'X-User-Agent': customUserAgent,
			}),
		},
	});

	const timeStartLink = new ApolloLink((operation, forward) => {
		const { operationName = '', variables = {} } = operation;
		const transactionSpan = startInactiveSpan({
			name: operationName,
			data: {
				variables: JSON.stringify(variables),
				...variables,
			},
			op: 'graphql.resolve',
		});
		setTag(`transaction.query.${operationName}`, operationName);

		operation.setContext({ transactionSpan });

		return forward(operation);
	});

	const logTimeLink = new ApolloLink((operation, forward) => {
		return forward(operation).map(data => {
			const transactionSpan = operation.getContext().transactionSpan;
			transactionSpan?.finish();
			return data;
		});
	});

	const timeLinks = timeStartLink.concat(logTimeLink);

	const httpLinks = timeLinks.concat(httpLink);

	subscriptionClient = new SubscriptionClient(wsUrl, {
		reconnect: true,
		lazy: true,
		reconnectionAttempts: 10,
	});

	const wsMainLink = new WebSocketLink(subscriptionClient);

	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				typeof window !== 'undefined' &&
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		wsMainLink,
		httpLinks,
	);

	const featureDeployLink = new ApolloLink((operation, forward) => {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				...addIfObj(!!process.env.FEATURE_DEPLOY, {
					'X-Feature-Deploy': process.env.FEATURE_DEPLOY,
				}),
			},
		}));

		return forward(operation);
	});

	const apolloLinks = ApolloLink.from([
		onError(({ networkError, operation, response }) => {
			if (response?.errors) {
				response?.errors?.map((error, idx, arr) => {
					const { message, locations, path, extensions } = error;
					const { statusString = '' } = extensions || {};
					console.info({ error }, 'onError');
					addBreadcrumb({
						category: 'graphql.error',
						message: `[GraphQL error]: Message: ${message}, QueryName: ${
							operation.operationName
						}, Variables: ${JSON.stringify(
							operation.variables,
						)}, Location: ${locations}, Path: ${path}`,
						level: 'error',
					});
					addBreadcrumb({
						category: 'graphql.error',
						message: operation?.query?.loc?.source?.body
							?.trim()
							?.replace(/\t/g, '')
							?.replace(/\"/g, '"')
							?.replace(/\n/g, ' '),
						level: 'info',
					});

					captureMessage(
						`[GraphQL error]: Message: ${message}, QueryName: ${
							operation.operationName
						}, Variables: ${JSON.stringify(
							operation.variables,
						)}, Location: ${locations}, Path: ${path}`,
						'error',
					);
					!isProd &&
						console.error(
							`[GraphQL error]: Message: ${message},\n \tQueryName: ${
								operation.operationName
							},\n \tVariables: ${JSON.stringify(
								operation.variables,
								null,
								2,
							)},\n \tLocation: ${locations},Path: ${path},\n \tQuery: ${operation?.query?.loc?.source?.body
								?.trim()
								?.replace(/\t/g, '')
								?.replace(/\"/g, '"')
								?.replace(/\n/g, ' ')}`,
						);

					captureException(new Error(message));
				});
			}

			// To retry on network errors, we recommend the RetryLink
			// instead of the onError link. This just logs the error.
			if (networkError) {
				if (networkError?.message?.includes(ApolloErrorsEnums.TimeoutError)) {
					networkError.statusCode = 504;
					if (!networkError?.name) {
						networkError.name = ApolloErrorsEnums.TimeoutError;
					}
				}

				captureException(networkError);
			}
		}),
		featureDeployLink,
		splitLink,
	]);
	client = new ApolloClient({
		ssrMode: typeof window === 'undefined',
		link: apolloLinks,
		headers: {
			'gateway-user-agent': 'WEB',
			'x-language': lang,
			'x-tribuna-app': appname,
		},
		defaultOptions,
		cache: new InMemoryCache(cacheConfig),
	});
	return client;
}

export function initializeApollo({
	initialState = null,
	appname = '',
	lang = 'en',
} = {}) {
	const computedLang = lang.replace('default', 'en');
	let uri = appname ? `${appname}/${computedLang}/` : `${computedLang}/`;
	globalUri = uri;
	const _apolloClient = apolloClient ?? createApolloClient({ uri });

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Merge the initialState from getStaticProps/getServerSideProps in the existing cache
		const data = merge(existingCache, initialState, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
			],
		});

		// Restore the cache with the merged data
		_apolloClient.cache.restore(data);
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') return _apolloClient;
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;

	return _apolloClient;
}

export function addApolloState(client, pageProps) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
	}

	return pageProps;
}

export function useApollo(pageProps) {
	const state = pageProps[APOLLO_STATE_PROP_NAME];
	const store = useMemo(
		() => initializeApollo({ initialState: state }),
		[state],
	);
	return store;
}

client = createApolloClient({ uri: globalUri });
export { client, subscriptionClient };
