import {
	MatchPollOption,
	TagSeoPageType,
	TagType,
	TagType as TagTypeV2,
} from '@ui-kit/main-api-types';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import { TagPageSeoStates } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';
import {
	StatInOut,
	StatTransferSort,
	StatWindowTransfers,
} from '@ui-kit/main-int-api-types';
import isBetween from 'dayjs/plugin/isBetween';

import { MatchStatus } from '@generated-graphql';

import {
	INativeAdsConfigItem,
	LANG,
	Tab,
	TagFixturesTabs,
	TagSubTypesUrls,
	TagTabTypes,
	TagTypesUrls,
	TransferFilterType,
	UserRoles,
} from './types';

dayjs.extend(isBetween);

export const GOOGLE_ID =
	'203186115143-p7g068c2js76mle1b4jplmcs6mjm31fs.apps.googleusercontent.com';

export const GOOGLE_PAGESPEED_USER_AGENT = 'moto g power';
export const FACEBOOK_ID = 634116250331790;
export const ANIMATION_DURATION_USERCARD = 500;

export const TOP_LEAGUES = [
	'epl',
	'la_liga',
	'bundesliga',
	'seria_a',
	'ligue_1',
];
export const PARTNERS_APPNAMES = ['championsleague', 'arsenal', 'manutd'];
export const MONTH_NAMES = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const TRIBUNA_COM_URL = process.env.NEXT_PUBLIC_MAIN_PROJECT_URL
	? `${process.env.NEXT_PUBLIC_MAIN_PROJECT_URL}/`
	: 'https://tribuna.com/';

export const PROJECT_APPLICATION_LOGO =
	'https://pictures.tribuna.com/image/091e7038-ef22-4d46-b3c9-9a72ae329844/';
export const TWITTER_USERNAME = 'trbn_football';

export const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
export const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
export const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;

/** нужно для предотвращения случайного свайпа во время клика */
export const THRESHOLD = 1;

/** произвольное количество слайдов произвольной ширины */
export const SLIDES_PER_VIEW_MODE = 'auto';

/** число видимых слайдов в карусели */
export const MOBILE_SLIDES_PER_VIEW = 1;
export const DESKTOP_SLIDES_PER_VIEW = 3;

/** размеры элементов турнирного дерева */
export const TITLE_HEIGHT = 48;
export const PAIR_HEIGHT = 48;
export const SIDE_INDENT = 28;
export const SMALL_INDENT = 20;
export const LARGE_INDENT = 32;
export const DIFFERENT_LAYERS_OFFSET = 8;

/** количество входящих пар в зависимости от типа раунда */
export const INITIAL_PAIR_INPUT_COUNT = 0;
export const EMPTY_PAIR_INPUT_COUNT = 1;
export const REGULAR_PAIR_INPUT_COUNT = 2;

export const TBA_ID = 'tba';

export const WINNER = {
	HOME: 'HOME',
	AWAY: 'AWAY',
};

export const PAIR_VALUE_TYPE = {
	FOOTBALL_MATCH: 'statMatch',
	FOOTBALL_SERIES: 'statMatchSeries',
	DOTA_SERIES: 'DotaSeries',
	SKIPPED_MATCH: 'graphSkipPair',
	EMPTY_MATCH: 'graphEmptyPair',
};

export const modifiers = {
	noScroll: 'no-scroll',
};

export const DOCS_POST_SB = {
	ar: {
		contacts: '44ea72d2-36a7-45d3-80c9-59577f97d74c',
		cookies: 'd7c83e69-f9af-43b9-aa78-448b9faa3b9e',
		termsOfUse: 'a52be6cd-ae22-480a-80ea-ef7df852a61e',
		privacy: '43cca23e-930f-4894-973e-1a747de76c0b',
	},
	de: {
		contacts: '86eb7d9c-a6d4-4433-9c14-47f6260ca842',
		cookies: '32c5a70c-c6b9-485a-8c2b-c1f0716e1a74',
		termsOfUse: '2d2375b8-74e3-4bcf-a04d-1f47c7743cba',
		privacy: '9aaa4d50-79ca-4816-bcd7-c10980314ae0',
	},
	en: {
		contacts: 'b27fa08e-88f7-4108-9682-75dfa05bf26c',
		cookies: 'd521ed88-3945-432f-95de-110e8ab454cf',
		termsOfUse: '171c9c62-c887-42ad-9aec-db0774c5eec3',
		privacy: 'a5f51373-187c-47f3-a2f0-c47a7186f408',
	},
	es: {
		contacts: '4e72a9e2-c61d-4a39-b771-d0151f5f42a2',
		cookies: '10942f27-add4-4ff6-b80b-86731f68de1f',
		termsOfUse: 'eb1efba3-dd13-4d67-a6dc-3b8db1431602',
		privacy: 'dd37b996-1b78-488c-bb17-319d29360ef0',
	},
	fr: {
		contacts: 'd84f40b3-a8ca-446f-a702-c361e372a462',
		cookies: 'dc6b5015-cb39-49f7-b581-199008ae2afc',
		termsOfUse: '9cdcd9ec-35b5-4cf7-89ed-88cb09b78f7f',
		privacy: '8e2a5bfa-2061-4266-b576-7d60613a5dbd',
	},
	it: {
		contacts: 'de1852c4-c719-4e82-ba97-e26568dc449b',
		cookies: 'b3bef4c0-6f78-4d98-aa09-6fcdfe7eb88e',
		termsOfUse: 'c3afcafb-81ff-4b72-8443-f99ce6de8f2d',
		privacy: 'e65cad03-3700-4d8d-81c2-f417a38aed30',
	},
};

type INativeAdsConfig = Record<string, INativeAdsConfigItem>;

export const nativeAdsConfig: INativeAdsConfig = {
	'hotels.com': {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl: 'http://www.jdoqocy.com/click-100636397-13831060',
		branding: {
			backgroundColor: '#D32F2F',
			logo: {
				svg: 'hotelsComWhiteLogo',
			},

			mobileBranding: [
				'https://pictures.tribuna.com/image/736c1b11-9209-49fa-9e78-bc1a461d8d79',
			],
			mobileTooltip: {
				logo: {
					svg: 'hotelsTooltipLogo',
				},
			},
		},
	},
	panini: {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl:
			'https://track.webgains.com/click.html?wgcampaignid=1503395&wgprogramid=268155&wgtarget=https://paninishop.de/detail/index/sArticle/62278/sCategory/135',
		branding: {
			backgroundColor: '#FFED00',
			logo: {
				svg: 'Panini-group-logo',
			},
			mobileBranding: [
				'https://pictures.tribuna.com/image/2cf5ab37-32d8-498d-b623-5524bace027b',
			],
			mobileTooltip: {
				logo: {
					img: 'https://pictures.tribuna.com/image/f64916d6-0f08-4fa7-bb2d-6fa8f1c770a5?q=80',
				},
			},
		},
	},
	'nike.com': {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl:
			'https://www.anrdoezrs.net/click-100636397-13067763?url=https%3A%2F%2Fwww.nike.com%2Fw%2Fchelsea-fc-2yc65',
		branding: {
			backgroundColor: '#FFFFFF',
			logo: {
				svg: 'Nike-logo',
			},
			mobileBranding: [
				'https://pictures.tribuna.com/image/830fbf57-bf46-4577-9a8b-eef93278162b',
			],
			mobileTooltip: {
				logo: {
					img: 'https://pictures.tribuna.com/image/40e7acfa-ee08-4292-8845-a8c60bc9ef8c?q=80',
				},
			},
		},
	},
	fbet: {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl:
			'https://clicks.affijet.com/click?offer_id=385&partner_id=3452&utm_medium=Affiliate&landing_id=868',
		branding: {
			backgroundColor: '#031125',
			logo: {
				svg: 'Fbet-logo',
			},
			mobileBranding: [
				'https://pictures.tribuna.com/image/2bbf7599-a67e-4496-8571-2c481eaa6330',
			],
			mobileTooltip: {
				logo: {
					// img: 'https://pictures.tribuna.com/image/2bbf7599-a67e-4496-8571-2c481eaa6330',
					svg: 'Fbet-logo-tooltip',
				},
			},
		},
	},
	new_fbet: {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl:
			'https://clicks.affijet.com/click?offer_id=404&partner_id=3452&landing_id=632&utm_medium=Affiliate&sub_1={news_bonus}&sub_2={sport}',
		branding: {
			backgroundColor: '#031125',
			logo: {
				svg: 'Fbet-logo',
			},
			mobileBranding: [
				'https://pictures.tribuna.com/image/2bbf7599-a67e-4496-8571-2c481eaa6330',
			],
			mobileTooltip: {
				logo: {
					// img: 'https://pictures.tribuna.com/image/2bbf7599-a67e-4496-8571-2c481eaa6330',
					svg: 'Fbet-logo-tooltip',
				},
			},
		},
	},
	boomerang: {
		disableInfiniteScroll: true,
		disableAds: true,
		clickUrl: 'https://boomertraff.com/g34c2c2d6',
		branding: {
			backgroundColor: '#082053',
			logo: {
				// svg: 'Boomerang-logo',
				img: 'https://pictures.tribuna.com/image/e1ee4227-8ff8-4661-8fe6-81375f4e307e',
			},
			mobileBranding: [
				'https://pictures.tribuna.com/image/08cf8c71-e843-4a66-b32b-5eba350cb156',
			],
			mobileTooltip: {
				logo: {
					img: 'https://pictures.tribuna.com/image/e1ee4227-8ff8-4661-8fe6-81375f4e307e',
					// svg: 'Boomerang-logo',
				},
			},
		},
	},
};

export const nativeAds = {
	'liverpoolfc-2022-09-18-faroe-islands-and-4-more-unusual-places-on-the-planet-where-you-can-find-liverpool-fan-cl':
		{
			...nativeAdsConfig['hotels.com'],
		},
	'liverpoolfc-2022-09-25-liverpools-longest-ever-away-days-in-football': {
		...nativeAdsConfig['hotels.com'],
	},
	'liverpoolfc-2022-10-08-drunk-bellamy-in-barcelona-grobbelaars-wobbling-knees-in-rome-and-more-liverpools-most-ou':
		{
			...nativeAdsConfig['hotels.com'],
		},
	'liverpoolfc-2022-10-29-draft-atmospheric-cosy-and-budgetsaving-top-7-accomodation-places-in-liverpool-that-wont-':
		{
			...nativeAdsConfig['hotels.com'],
		},
	'fcbayern-2023-01-04-bayernfans-sammeln-gern-paninikartchen-und-verdienen-damit-astronomische-summen-beispiele':
		{
			...nativeAdsConfig['panini'],
		},
	'fcbayern-2023-01-10-wie-hat-sich-thomas-muller-vom-beginn-seiner-karriere-bis-heute-verandert-wir-zeigen-es-a':
		{
			...nativeAdsConfig['panini'],
		},
	'dortmund-2022-12-25-schlotterbeck-benutzte-keine-handys-adeyemi-hatte-fur-eine-andere-europaische-nationalman':
		{
			...nativeAdsConfig['panini'],
		},
	'dortmund-2023-01-26-wie-hat-sich-marco-reus-vom-beginn-seiner-karriere-bis-heute-verandert-wir-zeigen-es-auf':
		{
			...nativeAdsConfig['panini'],
		},
	'chelsea-2023-02-19-can-you-correctly-guess-these-7-iconic-chelsea-moments-when-theyre-drawn-in-ms-paint':
		{
			...nativeAdsConfig['nike.com'],
		},
	'chelsea-2023-02-19-answered-7-iconic-chelsea-moments-drawn-in-ms-paint': {
		...nativeAdsConfig['nike.com'],
	},
	'chelsea-2023-02-21-4-types-of-chelsea-stars-starterpack': {
		...nativeAdsConfig['nike.com'],
	},
	'chelsea-2023-02-27-dont-post-or-share-on-facebook-8-coolest-chelsea-kits-by-nike-rated-the-red-one-in':
		{
			...nativeAdsConfig['nike.com'],
		},

	'fcbarcelona-2023-03-05-do-not-post-this-10-coolest-barca-kits-by-nike-centenary-edition-iconic-messi-and-ronaldi':
		{
			...nativeAdsConfig['nike.com'],
		},
	'fcbarcelona-2023-03-08-can-you-identify-these-7-iconic-barca-moments-now-that-weve-drawn-them-in-ms-paint':
		{
			...nativeAdsConfig['nike.com'],
		},
	'fcbarcelona-2023-03-10-how-to-look-dress-like-a-top-cule-but-impress-people-even-in-madrid-city-centre-4-starter':
		{
			...nativeAdsConfig['nike.com'],
		},
	'arsenal-2023-03-05-bet-on-arsenal-to-beat-villa-and-catch-freebets': {
		...nativeAdsConfig['fbet'],
	},
	'manutd-2023-03-05-bet-on-man-united-to-beat-liverpool-catch-freebets': {
		...nativeAdsConfig['fbet'],
	},
	'manutd-2023-03-30-why-man-uniteds-next-transfer-should-be-this-canadian-and-nope-its-not-alphonso-davies':
		{
			...nativeAdsConfig['fbet'],
		},
	'fcbarcelona-2023-03-30-why-barcas-next-transfer-should-be-this-canadian-and-nope-its-not-alphonso-davies':
		{
			...nativeAdsConfig['fbet'],
		},
	'liverpoolfc-2023-03-30-bet-on-liverpool-to-beat-man-city-and-catch-freebets':
		{
			...nativeAdsConfig['fbet'],
		},
	'fcbarcelona-2023-03-31-fati-replacement-3-more-canadian-footballers-barca-should-take-a-look-at':
		{
			...nativeAdsConfig['fbet'],
		},
	'fcbarcelona-2023-04-04-potential-suarez-successor-puskas-contender-and-more-4-canadians-who-couldve-once-ended-u':
		{
			...nativeAdsConfig['fbet'],
		},
	'fcbarcelona-2023-04-12-3-picturesque-locations-in-canada-where-barca-can-go-for-an-unforgettable-training-camp':
		{
			...nativeAdsConfig['fbet'],
		},
	'liverpoolfc-2023-04-07-bet-on-liverpool-to-beat-arsenal-and-catch-freebets':
		{
			...nativeAdsConfig['fbet'],
		},
	'fcbarcelona-2023-04-24-scouting-the-world-4-promising-canadian-players-top-european-clubs-should-have-on-their-r':
		{
			...nativeAdsConfig['fbet'],
		},

	'manutd-2023-05-26-bet-on-man-united-to-beat-fulham-and-catch-freebets': {
		...nativeAdsConfig['new_fbet'],
	},
	'arsenal-2023-05-26-bet-on-arsenal-to-beat-wolves-and-catch-freebets': {
		...nativeAdsConfig['new_fbet'],
	},
	'fcbayern-2024-06-14-kane-macht-hoffnung-musiala-ist-sicherlich-unterschatzt-wer-wird-der-beste-torschutze-der':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-14-vorschau-auf-das-emauftaktspiel-hoffnungsvolles-deutschland-trifft-auf-kampfstarke-schott':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-14-vorschau-auf-den-spieltag-finalwurdiges-duell-entscheidendes-spiel-und-klarer-favorit':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-15-vorschau-auf-den-dritten-emtag-der-auftakt-fur-england-polen-spielt-ohne-lewandowski-gege':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-16-vorschau-auf-den-vierten-emtag-belgiens-verrostete-generation-mudryk-und-mbappe-starten-i':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-14-wird-deutschland-nur-gruppenzweiter-verpasst-italien-die-korunde-experten-tippen-fur-die':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-19-deutschland-gehort-zu-den-favoriten-belgien-enttauscht-wie-sich-die-quoten-fur-den-europa':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-17-gruppe-f-schliet-den-ersten-emspieltag-ab-debut-von-georgien-und-ronaldo-gegen-meister-vo':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-19-die-zweite-runde-beginnt-deutschland-und-die-schweiz-konnen-die-playoffs-erreichen-kroati':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-19-vorschau-auf-deutschlands-zweites-emspiel-wie-stehen-die-chancen-auf-einen-sieg-gegen-ung':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-19-vorschau-auf-den-7-emtag-wiederholung-der-euro-2020halbfinalspiele-und-ein-unberechenbare':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-20-upamecano-vs-de-ligt-laimer-vs-lewandowski-vorschau-auf-den-8-tag-der-europameisterschaft':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-21-ende-des-zweiten-emspieltags-neue-chance-fur-georgien-wichtiges-spiel-zwischen-belgien-un':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-23-der-dritte-emspieltag-beginnt-deutschland-hat-sich-die-korunde-schon-gesichert-aber-der-k':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-23-vorschau-auf-den-entscheidenden-spieltag-der-gruppe-b-kroatien-kampft-gegen-italien-um-di':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-24-wer-kommt-weiter-in-zwei-gruppen-wird-der-starkste-ermittelt-vorschau-auf-den-11-spieltag':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-26-ukraine-gegen-belgien-tschechien-gegen-die-turkei-was-ist-am-letzten-gruppenspieltag-der':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-27-die-favoriten-der-euro-2024-vor-beginn-der-korunde-deutschland-ruckt-naher-an-die-spitze':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-27-turnier-bei-dem-es-um-15000-euro-geht-einfach-auf-dein-euro2024team-tippen-und-den-bonus':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-27-kane-mbappe-und-ein-deutscher-spieler-der-im-nachsten-spiel-der-euro-2024-treffen-soll':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-28-dfbteam-im-viertelfinale-frankreich-und-england-scheiden-aus-experten-tippen-auf-die-euro':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-28-quoten-fur-das-achtelfinale-der-euro-2024-deutschland-und-spanien-sollen-keine-probleme-h':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-06-29-jetzt-geht-es-richtig-los-deutschland-danemark-italien-und-die-schweiz-kampfen-um-die-ers':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-06-29-wer-zieht-mit-der-schweiz-und-deutschland-ins-viertelfinale-ein-england-muss-aufwachen-sp':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-01-dritter-spieltag-der-korunde-topspiel-frankreich-gegen-belgien-slowenien-konnte-erneut-po':
		{
			...nativeAdsConfig['boomerang'],
		},
	'dortmund-2024-07-03-sahin-gegen-deutschland-adeyemi-fur-eine-unerwartete-nationalmannschaft-worauf-die-bvbsta':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-07-prognosen-fur-die-em-2024-tiktokhund-vs-lothar-matthaus-wer-versteht-mehr-vom-fuball':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-04-ac-mailand-schliet-neuen-regionalen-partnerschaftsvertrag-mit-boomerang-ab':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-04-schlagt-jamal-den-jungeren-yamal-ronaldo-oder-mbappe-experten-tippen-auf-die-em-2024':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-04-zwei-bayernspieler-unter-den-top-3-die-favoriten-auf-den-torschutzenkonig-bei-der-em':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-04-welche-chancen-hat-deutschland-die-favoriten-auf-den-sieg-bei-der-euro-2024-vor-dem-viert':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-02-die-letzten-spiele-des-emachtelfinales-die-niederlande-haben-zwei-grunde-weiterzukommen-o':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-04-deutschland-auf-titeljagd-showdown-gegen-spanien-und-cr7-gegen-mbappe-emviertelfinale-sta':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-06-zweites-emviertelfinale-schwierige-aufgabe-fur-kane-und-co-simons-und-die-niederlande-tra':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-07-spanien-hofft-auf-yamal-frankreich-hofft-endlich-ein-tor-zu-erzielen-vorschau-auf-das-ers':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-09-ein-schritt-bis-zum-finale-spanien-gegen-frankreich-und-england-gegen-niederlande-experte':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-13-wer-holt-die-trophae-spanien-oder-england-experten-tippen-das-emfinale':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-15-igb-lve-2024-boomerang-prasentiert-partnerschaft-mit-ac-mailand-und-neue-affiliatemoglich':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-07-10-wer-zieht-mit-spanien-ins-finale-ein-simons-gegen-kane-die-niederlande-und-ihr-traum-gege':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-12-english-or-spanish-yamal-oder-bellingham-vorschau-auf-das-finale-der-europameisterschaft':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-07-26-igb-amsterdam-boomerang-presents-ac-milan-partnership-with-match-ticket-giveaways':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-08-02-juventus-women-star-alisha-lehmann-signs-partnership-deal-with-boomerang':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-08-02-boomerang-kundigt-zusammenarbeit-mit-frauenfuballstar-alisha-lehmann-an':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-09-boomerang-bietet-reise-fur-gewinner-des-gewinnspiels-von-sigma-east-europe-2024-zum-san-s':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-10-boomerang-offers-trip-to-san-siro-for-ac-milan-game-for-winners-of-sigma-east-europe-2024':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-13-boomerang-partners-feiert-3jahriges-jubilaum-mit-funffachem-wachstum-im-sportwettensegmen':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-13-boomerang-partners-celebrates-3-years-with-fivefold-increase-in-sports-betting-segment':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-11-alisha-lehmann-die-heieste-fuballerin-der-welt-eine-feministin-im-kampf-gegen-sexismus-un':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-12-alisha-lehmann-la-calciatrice-piu-sexy-del-mondo-una-femminista-in-lotta-contro-il-sessis':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-12-alisha-lehmann-the-hottest-footballer-in-the-world-a-feminist-fighting-sexism-and-the-new':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-12-topspieler-von-der-euro-2024-neuer-dani-alves-und-mehr-bewertung-der-sommertransfers-von':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-12-euro-2024-top-performer-new-dani-alves-and-more-rating-ac-milans-summer-transfers':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-09-12-welche-chancen-hat-harry-kane-den-ballon-dor-in-paris-zu-gewinnen-boomerang-sieht-ihn-in':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-16-hilft-das-neue-championsleagueformat-einer-deutschen-mannschaft-das-finale-zu-gewinnen-wi':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-09-16-der-fc-bayern-startet-in-die-championsleaguesaison-202425-setzt-sich-der-rekordmeister-ge':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbarcelona-2024-09-16-will-new-champions-league-format-help-barca-win-will-lewandowski-be-top-goalscorer-boomer':
		{
			...nativeAdsConfig['boomerang'],
		},
	'realmadrid-2024-09-16-will-real-madrid-defend-champions-league-trophy-will-mbappe-win-golden-boot-again-boomera':
		{
			...nativeAdsConfig['boomerang'],
		},

	'arsenal-2024-09-16-will-the-new-champions-league-format-help-arsenal-win-the-final-will-haaland-or-harry-kan':
		{
			...nativeAdsConfig['boomerang'],
		},
	'liverpoolfc-2024-09-16-will-the-new-champions-league-format-help-liverpool-win-the-final-will-haaland-or-salah-b':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-21-alvaro-morata-to-score-for-368-more-boomerang-offer-tasty-bets-for-inter-milan-v-ac-milan':
		{
			...nativeAdsConfig['boomerang'],
		},
	'acmilan-2024-09-22-alvaro-morata-segnera-a-quota-368-e-molto-piu-quote-e-pronostici-di-boomerang-per-il-derb':
		{
			...nativeAdsConfig['boomerang'],
		},
	'inter-2024-09-22-lautaro-martinez-segnera-a-quota-235-e-molto-piu-quote-e-pronostici-di-boomerang-per-il-d':
		{
			...nativeAdsConfig['boomerang'],
		},
	'dortmund-2024-09-20-borussia-dortmund-trifft-auf-den-vfb-stuttgart-wird-der-bvb-seine-ungeschlagene-serie-for':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-22-borussia-dortmund-v-stuttgart-is-bundesligas-highestscoring-clash-will-jamie-bynoegittens':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-17-sbc-summit-2024-prasentiert-die-igamingangebote-von-boomerang-und-exklusive-verlosung-von':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-18-sbc-summit-2024-will-feature-boomerangs-igaming-offerings-and-exclusive-ac-milan-ticket-g':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-27-boomerang-gewinnt-rising-star-im-bereich-sportwetten-bei-den-sbc-awards-2024':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-27-boomerang-win-rising-star-in-sport-betting-at-sbc-awards-2024':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-24-wer-gewinnt-die-europa-league-erzielt-marcus-rashford-ein-tor-gegen-twente-boomerang-biet':
		{
			...nativeAdsConfig['boomerang'],
		},
	'manutd-2024-09-24-who-will-win-europa-league-will-marcus-rashford-score-v-twente-boomerang-offer-attractive':
		{
			...nativeAdsConfig['boomerang'],
		},
	'fcbayern-2024-09-26-fc-bayern-vor-dem-bundesligakracher-gegen-bayer-leverkusen-vincent-kompanys-team-steht-vo':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-27-bayern-munich-v-bayer-leverkusen-vincent-kompanys-side-face-first-big-test-find-all-info':
		{
			...nativeAdsConfig['boomerang'],
		},
	'realmadrid-2024-09-28-atletico-madrid-gegen-real-madrid-wird-sich-carlo-ancelottis-mannschaft-erneut-im-wanda-m':
		{
			...nativeAdsConfig['boomerang'],
		},
	'realmadrid-2024-09-28-atletico-madrid-v-real-madrid-will-carlo-ancelottis-side-struggle-at-wanda-metropolitano':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-30-pulisic-erzielt-ein-tor-und-mehr-boomerang-bietet-attraktive-quoten-fur-das-groe-champion':
		{
			...nativeAdsConfig['boomerang'],
		},
	'football-2024-09-30-pulisic-to-score-more-boomerang-offer-attractive-odds-for-big-champions-league-clash-betw':
		{
			...nativeAdsConfig['boomerang'],
		},
};

export const WEEK_DAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const LANGS = ['en', 'it', 'es', 'fr', 'de', 'ar'];

export const FOOTBALL_ON_DIFFERENT_LANGS = {
	en: 'Football',
	it: 'Calcio',
	es: 'Fútbol',
	fr: 'Football',
	de: 'Fußball',
};

export const MATCH_DURATION = 90;
export const BLOGS_PER_PAGE = 15;
export const TAGS_PER_PAGE = 50;

export const YEAR_MS = 24 * 3600 * 365.25 * 1000;

export const TRANSFERS_TAG_ID = 'transfers_general';
export const TRANSFERS_TAG_ID_UNIFICATED = '1685208';

export const TABS: Tab[] = [
	{
		id: TagTabTypes.Main,
		title: 'main',
		link: '',
	},
	{
		id: TagTabTypes.News,
		title: 'news',
		link: 'news',
	},
	{
		id: TagTabTypes.Blogs,
		title: 'blogs',
		link: 'blogs',
	},
	{
		id: TagTabTypes.Fixtures,
		title: 'fixtures',
		link: 'fixtures',
	},
	{
		id: TagTabTypes.Teams,
		title: 'teams',
		link: 'teams',
	},
	{
		id: TagTabTypes.Table,
		title: 'standings',
		link: 'table',
	},
	{
		id: TagTabTypes.Career,
		title: 'career',
		link: 'career',
	},
	{
		id: TagTabTypes.Coach,
		title: 'coach',
		link: 'coach',
	},
	{
		id: TagTabTypes.Results,
		title: 'results',
		link: 'results',
	},
	{
		id: TagTabTypes.Rumours,
		title: 'rumours',
		link: 'rumours',
	},
	{
		id: TagTabTypes.Stadium,
		title: 'stadium',
		link: 'stadium',
	},
	{
		id: TagTabTypes.Stats,
		title: 'stats',
		link: 'stats',
	},
	{
		id: TagTabTypes.Transfers,
		title: 'transfers',
		link: 'transfers',
	},
];

export const LEAGUE_TABS: Tab[] = [
	{
		id: TagTabTypes.Main,
		title: 'main',
		link: '',
	},
	{
		id: TagTabTypes.News,
		title: 'news',
		link: 'news',
	},
	{
		id: TagTabTypes.Blogs,
		title: 'blogs',
		link: 'blogs',
	},
	{
		id: TagTabTypes.Fixtures,
		title: 'fixtures',
		link: 'fixtures',
	},
	{
		id: TagTabTypes.Teams,
		title: 'teams',
		link: 'teams',
	},
	{
		id: TagTabTypes.Table,
		title: 'standings',
		link: 'table',
	},
	{
		id: TagTabTypes.Transfers,
		title: 'transfers',
		link: 'transfers',
	},
	{
		id: TagTabTypes.PlayoffIntClubCup,
		title: 'draw',
		link: 'draw',
	},
	{
		id: TagTabTypes.PlayoffCup,
		title: 'draw',
		link: 'draw',
	},
	{
		id: TagTabTypes.PlayoffLeague,
		title: 'playoff',
		link: 'playoff',
	},
	{
		id: TagTabTypes.PlayoffWorldCup,
		title: 'bracket',
		link: 'bracket',
	},
	{
		id: TagTabTypes.Stats,
		title: 'stat',
		link: 'stats',
	},
];

export const CLUBS_TABS: Tab[] = [
	{
		id: TagTabTypes.Main,
		title: 'main',
		link: '',
	},
	{
		id: TagTabTypes.News,
		title: 'news',
		link: 'news',
	},
	{
		id: TagTabTypes.Blogs,
		title: 'blogs',
		link: 'blogs',
	},
	{
		id: TagTabTypes.Fixtures,
		title: 'fixtures',
		link: 'fixtures',
	},
	{
		id: TagTabTypes.Table,
		title: 'standings',
		link: 'table',
	},
	{
		id: TagTabTypes.Teams,
		title: 'squad',
		link: 'squad',
	},
	{
		id: TagTabTypes.Career,
		title: 'career',
		link: 'career',
	},
	{
		id: TagTabTypes.Coach,
		title: 'coach',
		link: 'coach',
	},
	{
		id: TagTabTypes.Results,
		title: 'results',
		link: 'results',
	},
	{
		id: TagTabTypes.Rumours,
		title: 'rumours',
		link: 'rumours',
	},
	{
		id: TagTabTypes.Stadium,
		title: 'stadium',
		link: 'stadium',
	},
	{
		id: TagTabTypes.Stats,
		title: 'stat',
		link: 'squad/stat',
	},
	{
		id: TagTabTypes.Transfers,
		title: 'transfers',
		link: 'transfers',
	},
];

export const PERSON_TABS: Tab[] = [
	{
		id: TagTabTypes.Main,
		title: 'profile',
		link: '',
	},
	{
		id: TagTabTypes.News,
		title: 'news',
		link: 'news',
	},
	{
		id: TagTabTypes.Blogs,
		title: 'blogs',
		link: 'blogs',
	},
	{
		id: TagTabTypes.Fixtures,
		title: 'fixtures',
		link: 'fixtures',
	},
	{
		id: TagTabTypes.Career,
		title: 'career',
		link: 'career',
	},
	{
		id: TagTabTypes.Teams,
		title: 'teams',
		link: 'teams',
	},
	{
		id: TagTabTypes.Table,
		title: 'standings',
		link: 'table',
	},
	{
		id: TagTabTypes.Coach,
		title: 'coach',
		link: 'coach',
	},
	{
		id: TagTabTypes.Results,
		title: 'results',
		link: 'results',
	},
	{
		id: TagTabTypes.Rumours,
		title: 'rumours',
		link: 'rumours',
	},
	{
		id: TagTabTypes.Stadium,
		title: 'stadium',
		link: 'stadium',
	},
	{
		id: TagTabTypes.Stats,
		title: 'statistics',
		link: 'stat',
	},
	{
		id: TagTabTypes.Transfers,
		title: 'transfers',
		link: 'transfers',
	},
];

export const mostUsedModeratorRoles = [
	UserRoles.ModeratorBlog,
	UserRoles.ModeratorComment,
	UserRoles.ModeratorTag,
	UserRoles.ModeratorNews,
];

export const resultsMatchStatus = {
	buttonName: TagFixturesTabs.Results,
	matchStatus: [MatchStatus.Ended, MatchStatus.Closed, MatchStatus.Abandoned],
};

export const fixturesMatchStatus = {
	buttonName: TagFixturesTabs.Fixtures,
	matchStatus: [
		MatchStatus.NotStarted,
		MatchStatus.Live,
		MatchStatus.Postponed,
		MatchStatus.Delayed,
		MatchStatus.StartDelayed,
	],
};

export const SOCIAL_SHARING_LINKS = {
	twitterLink:
		'https://pictures.tribuna.com/image/61697e3f-759f-4615-be2f-08718f46ce63?width=900&height=900/',
	generalLink:
		'https://pictures.tribuna.com/image/091e7038-ef22-4d46-b3c9-9a72ae329844/',
};

export const tagTypeFromUrl: Record<TagTypesUrls, TagTypeV2> = {
	[TagTypesUrls.Clubs]: TagTypeV2.Team,
	[TagTypesUrls.NationalTeam]: TagTypeV2.Team,
	[TagTypesUrls.Persons]: TagTypeV2.Person,
	[TagTypesUrls.League]: TagTypeV2.Tournament,
	[TagTypesUrls.Other]: TagTypeV2.Other,
	[TagTypesUrls.AllTags]: TagTypeV2.Other,
};

export const tagsGroupNameFromUrl: Record<
	TagTypesUrls & TagSubTypesUrls,
	string
> = {
	[TagTypesUrls.AllTags]: 'all-tags',
	[TagTypesUrls.Clubs]: 'teams',
	[TagTypesUrls.NationalTeam]: 'national-teams',
	[TagTypesUrls.Persons]: 'sportsmen',
	[TagTypesUrls.League]: 'tournaments',
	[TagTypesUrls.Other]: 'others',
	[TagSubTypesUrls.Athlete]: 'players',
	[TagSubTypesUrls.Coach]: 'coach',
	[TagSubTypesUrls.Referee]: 'referees',
	[TagSubTypesUrls.Manager]: 'managers',
	[TagSubTypesUrls.Journalist]: 'journalist',
};

export const matchCenterDateRegExp =
	/((19)|(20))\d\d-(1([0-2])|(0[1-9]))-((0[1-9])|([12]\d)|(3[01]))/;

export const SOCIAL_LINKS = {
	APPS_LINK: 'https://tribuna.com/en/our-apps/',
	FACEBOOK_LINK: 'https://www.facebook.com/tribuna.barcelona.en',
	INSTAGRAM_LINK: 'https://www.instagram.com/barcaliveapp/',
	YOUTUBE_LINK: 'https://www.youtube.com/channel/UCZY7GQuKit1UrsrVTgbbgNA',
	LINKEDIN_LINK: ' https://www.linkedin.com/company/tribuna-com/',
};

export const HREF_LANGS = {
	ru: 'https://ua.tribuna.com/',
	uk: 'https://ua.tribuna.com/uk/',
	be: 'https://by.tribuna.com/be/',
	'ru-BY': 'https://by.tribuna.com/',
	'ru-RU': 'https://by.tribuna.com/',
	en: 'https://tribuna.com/en/',
	es: 'https://tribuna.com/es/',
	fr: 'https://tribuna.com/fr/',
	de: 'https://tribuna.com/de/',
	it: 'https://tribuna.com/it/',
	ar: 'https://tribuna.com/ar/',
};

export const POSTS_PAGE_SIZE = 20;
export const NEWS_PAGE_SIZE = 50;
export const INSTAGRAM_EMBED_SCRIPT_PATH =
	'/assets/scripts/instagram-embed.v1.js';

export const SPORT_NEWS_PAGE_URLS = {
	ru: 'news',
	uk: 'news',
	be: 'news',
	'ru-BY': 'news',
	'ru-RU': 'news',
	en: 'news',
	es: 'noticias',
	fr: 'actualites',
	de: 'nachrichten',
	it: 'notizie',
	ar: 'news',
};

export const OTHER_URLS = {
	ru: 'other',
	uk: 'other',
	be: 'other',
	'ru-BY': 'other',
	'ru-RU': 'other',
	en: 'other',
	es: 'otras',
	fr: 'autres',
	de: 'andere',
	it: 'altri',
	ar: 'other',
};

export const FOOTBALL_PAGE_URLS = {
	ru: 'football',
	uk: 'football',
	be: 'football',
	'ru-BY': 'football',
	'ru-RU': 'football',
	en: 'football',
	es: 'futbol',
	fr: 'football',
	de: 'fussball',
	it: 'calcio',
	ar: 'football',
};

export const DATA_DIR = 'data';
export const SEO_TEXT_FILE_NAME = 'seoText.json';
export const SEO_TEXT_FILE_PATH = `${DATA_DIR}/${SEO_TEXT_FILE_NAME}`;

export const TAG_SEO_TEXTS_POST_ID = {
	[TagType.Person]: {
		[TagSeoPageType.Stats]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-player-stats',
				[LANG.it]: '2024-12-03-it-player-stats',
				[LANG.es]: '2024-12-09-es-player-stats',
				[LANG.fr]: '2024-12-09-fr-player-stats',
				[LANG.de]: '2024-12-12-de-player-stats',
				[LANG.ar]: '2024-12-12-ar-player-stats',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: '2024-12-02-en-player-stats-season',
				[LANG.it]: '2024-12-03-player-stats-season',
				[LANG.es]: '2024-12-09-es-players-stats-season',
				[LANG.fr]: '2024-12-09-fr-player-stats-season',
				[LANG.de]: '2024-12-12-de-players-stats-season',
				[LANG.ar]: '2024-12-12-ar-player-stats-season',
			},
			[TagPageSeoStates.seasonAndTournament]: {
				[LANG.en]: '2024-12-02-en-players-stats-season-tournament',
				[LANG.it]: '2024-12-03-it-player-stats-season-league',
				[LANG.es]: '2024-12-09-es-player-stats-season-league',
				[LANG.fr]: '2024-12-12-fr-player-stats-season-league',
				[LANG.de]: '2024-12-12-de-player-stats-season-league',
				[LANG.ar]: '2024-12-12-ar-player-season-league',
			},
		},
		[TagSeoPageType.Career]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-players-career',
				[LANG.it]: '2024-12-03-it-player-career',
				[LANG.es]: '2024-12-09-es-player-career',
				[LANG.fr]: '2024-12-12-fr-player-career',
				[LANG.de]: '2024-12-12-de-player-career',
				[LANG.ar]: '2024-12-12-ar-player-career',
			},
		},
	},
	[TagType.Team]: {
		[TagSeoPageType.Table]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: 'name-table',
				[LANG.es]: 'clasificacion-de-name',
				[LANG.de]: 'name-tabellenstand-1',
				[LANG.it]: 'classifica-name',
				[LANG.fr]: 'classement-du-name',
				[LANG.ar]: 'name',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: 'tournament-table-name-season-1',
				[LANG.es]: 'tabla-de-torneos-nombre-temporada',
				[LANG.de]: 'turniertisch-name-season',
				[LANG.it]: 'tabella-del-torneo-name-season',
				[LANG.fr]: 'classement-du-tournoi-name-season',
				[LANG.ar]: '2024-11-20-name-season',
			},
			[TagPageSeoStates.seasonAndTournament]: {
				[LANG.en]: 'tournament-table-name-of-tournamentname-season',
				[LANG.es]: 'tabla-de-torneos-name-en-tournamentname-season',
				[LANG.de]: 'nameplatzierung-in-tournamentname-season',
				[LANG.it]: 'classifica-di-name-nella-tournamentname-season',
				[LANG.fr]: 'classement-de-name-dans-tournamentname-season',
				[LANG.ar]: '2024-11-20-name-tournamentname-season',
			},
		},
		[TagSeoPageType.Transfers]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-clubs-transfers',
				[LANG.es]: '2024-12-09-es-clubs-transfers',
				[LANG.de]: '2024-12-12-de-clubs-transfers',
				[LANG.it]: '2024-12-09-it-clubs-trasfers',
				[LANG.fr]: '2024-12-12-fr-clubs-trasfers',
				[LANG.ar]: '2024-12-12-ar-clubs-transfers',
			},
			[TagPageSeoStates.summer]: {
				[LANG.en]: '2024-12-02-en-clubs-transfers-season',
				[LANG.es]: '2024-12-09-es-clubs-transfers-season',
				[LANG.de]: '2024-12-12-de-clubs-transfers-season-summer',
				[LANG.it]: '2024-12-09-it-clubs-transfers-season',
				[LANG.fr]: '2024-12-12-fr-clubs-transfers-season-summer',
				[LANG.ar]: '2024-12-12-ar-clubs-transfers-season-summer',
			},
			[TagPageSeoStates.winter]: {
				[LANG.en]: '2024-12-02-en-clubs-trasfers-winter-season',
				[LANG.es]: '2024-12-09-es-clubs-transfers-season-winter',
				[LANG.de]: '2024-12-12-de-clubs-transfers-season-winter',
				[LANG.it]: '2024-12-09-it-clubs-transfers-season-winter',
				[LANG.fr]: '2024-12-12-fr-clubs-transfers-season-winter',
				[LANG.ar]: '2024-12-12-ar-clubs-transfers-season-winter',
			},
		},
		[TagSeoPageType.Teams]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-club-squad',
				[LANG.es]: '2024-12-09-es-clubs-squad',
				[LANG.de]: '2024-12-12-de-clubs-squad',
				[LANG.it]: '2024-12-09-it-clubs-squad',
				[LANG.fr]: '2024-12-12-fr-clubs-squad',
				[LANG.ar]: '2024-12-12-ar-clubs-squad',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: '2024-12-02-en-clubs-squad-season',
				[LANG.es]: '2024-12-09-es-clubs-squad-season',
				[LANG.de]: '2024-12-12-de-clubs-squad-season',
				[LANG.it]: '2024-12-09-it-clubs-squad-season',
				[LANG.fr]: '2024-12-12-fr-clubs-clubs-season',
				[LANG.ar]: '2024-12-12-ar-clubs-squad-season',
			},
		},
	},
	[TagType.Tournament]: {
		[TagSeoPageType.Transfers]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-league-transfers',
				[LANG.es]: '2024-12-09-es-league-transfers',
				[LANG.de]: '2024-12-12-de-league-transfers',
				[LANG.it]: '2024-12-09-it-league-transfers',
				[LANG.fr]: '2024-12-12-fr-league-transfers',
				[LANG.ar]: '2024-12-12-ar-league-transfers',
			},
			[TagPageSeoStates.summer]: {
				[LANG.en]: '2024-12-02-en-league-transfers-season',
				[LANG.es]: '2024-12-09-es-league-transfers-summer-season',
				[LANG.de]: '2024-12-12-de-league-transfers-season-summer',
				[LANG.it]: '2024-12-09-it-league-transfers-summer-season',
				[LANG.fr]: '2024-12-12-fr-league-transfers-season',
				[LANG.ar]: '2024-12-12-ar-league-transfers-season-summer',
			},
			[TagPageSeoStates.winter]: {
				[LANG.en]: '2024-12-02-en-league-transfers-winter-season',
				[LANG.es]: '2024-12-09-es-league-transfers-winter-season',
				[LANG.de]: '2024-12-12-de-league-transfers-season-winter',
				[LANG.it]: '2024-12-09-it-league-transfers-winter-season',
				[LANG.fr]: '2024-12-12-fr-league-transfers-season-winter',
				[LANG.ar]: '2024-12-12-ar-league-transfers-season-winter',
			},
		},
		[TagSeoPageType.Table]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-league-table',
				[LANG.es]: '2024-12-09-es-league-table',
				[LANG.de]: '2024-12-12-de-league-table',
				[LANG.it]: '2024-12-09-it-league-table',
				[LANG.fr]: '2024-12-12-fr-league-table',
				[LANG.ar]: '2024-12-12-ar-league-table',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: '2024-12-02-en-league-table-season',
				[LANG.es]: '2024-12-09-es-league-table-season',
				[LANG.de]: '2024-12-12-de-league-table-season',
				[LANG.it]: '2024-12-09-it-league-table-season',
				[LANG.fr]: '2024-12-12-fr-league-table-season',
				[LANG.ar]: '2024-12-12-ar-league-table-season',
			},
		},
		[TagSeoPageType.Fixtures]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-league-fixtures',
				[LANG.es]: '2024-12-09-es-league-calendar',
				[LANG.de]: '2024-12-12-de-league-calendar',
				[LANG.it]: '2024-12-09-it-league-calendar',
				[LANG.fr]: '2024-12-12-fr-league-calendar',
				[LANG.ar]: '2024-12-12-ar-league-calendar',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: '2024-12-02-en-league-fixtures-season',
				[LANG.es]: '2025-01-21-es-clubs-calendar-season',
				[LANG.de]: '2024-12-12-de-league-calendar-season',
				[LANG.it]: '2024-12-09-it-league-calendar-season',
				[LANG.fr]: '2024-12-12-fr-league-calendar-season',
				[LANG.ar]: '2024-12-12-ar-league-calendar-season',
			},
		},
		[TagSeoPageType.Stats]: {
			[TagPageSeoStates.default]: {
				[LANG.en]: '2024-12-02-en-league-stats',
				[LANG.es]: '2024-12-09-es-league-stats',
				[LANG.de]: '2024-12-12-de-league-stats',
				[LANG.it]: '2024-12-09-it-league-stats',
				[LANG.fr]: '2024-12-12-fr-league-stats',
				[LANG.ar]: '2024-12-12-ar-league-stats',
			},
			[TagPageSeoStates.seasons]: {
				[LANG.en]: '2024-12-02-en-league-stats-season',
				[LANG.es]: '2024-12-09-es-league-stats-season',
				[LANG.de]: '2024-12-12-de-league-stats-league',
				[LANG.it]: '2024-12-09-it-league-stats-season',
				[LANG.fr]: '2024-12-12-fr-league-stats-season',
				[LANG.ar]: '2024-12-12-ar-league-stats-season',
			},
		},
	},
};

export const FIREBASE_LANG_ENV = {
	apiKey: 'AIzaSyDcam93zssckR8BqtFKyyDsfOtESmxMwjI',
	authDomain: 'int-tribuna.firebaseapp.com',
	projectId: 'int-tribuna',
	storageBucket: 'int-tribuna.firebasestorage.app',
	messagingSenderId: '610631230229',
	appId: '1:610631230229:web:2aa8504906c9c87a6cbf1c',
	measurementId: 'G-F51JF6C5NL',
};
export const REVIEWS_PAGE_SIZE = 10;

export const DEFAULT_COMMENTS_SUPER_TAG = 'ALL_COMMENTS';
export const ADMIN_URL = 'https://admin.tribuna.com';
