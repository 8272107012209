import {
	Bookie,
	Casino,
	StatMatchList,
	StatSeason,
	TagSeoPageType,
	StatTournament,
} from '@ui-kit/main-api-types';
import {
	StatInOut,
	StatTeamSeasonRaw,
	StatTransferSort,
	StatWindowTransfers,
} from '@ui-kit/main-int-api-types';
import { Dayjs } from 'dayjs';

export type Tab = {
	id: number | string;
	title?: string;
	link?: string;
};

export type TagListItem = {
	logo: string;
	text: string;
	link?: string;
};

export enum AvatarType {
	Person = 'PERSON',
	User = 'USER',
	Team = 'TEAM',
	Tournament = 'TOURNAMENT',
	Other = 'OTHER',
	CountryFlag = 'COUNTRYFLAG',
	CountryCircle = 'COUNTRY_CIRCLE',
}

export enum ApolloErrorsEnums {
	AbortError = 'AbortError',
	TimeoutError = 'TimeoutError',
}

export enum ScrollDirection {
	Up = 'up',
	Down = 'down',
}

export enum MainTabType {
	Main,
}

export type TagTabTypes = MainTabType | TagSeoPageType;
export const TagTabTypes = {
	...MainTabType,
	...TagSeoPageType,
};

export enum TagTeamDirectories {
	Team = 'team',
	Clubs = 'clubs',
}

export enum TeamType {
	CLUB = 'club',
	NATIONAL = 'national_team',
}

export enum PROJECTS {
	INT = 'int',
	UA_BY = 'ua/by',
}

export enum TagDirectories {
	Team = 'team',
	Clubs = 'clubs',
	League = 'league',
	Persons = 'persons',
	Tags = 'other',
	OldTags = 'tags',
}

export type PagingTypes = {
	array: number[];
	type?: PagingType;
};

export enum PagingType {
	Start = 'start',
	End = 'end',
	Middle = 'middle',
}

export enum LANG {
	en = 'en',
	de = 'de',
	es = 'es',
	it = 'it',
	fr = 'fr',
	ar = 'ar',
}

export enum Footnotes {
	LastFive = 'last-five-desc',
	MatchPlayed = 'match-played-desc',
	Wins = 'wins-desc',
	Draws = 'draws-desc',
	Losses = 'losses-desc',
	GoalDifference = 'goal-difference-desc',
	GoalsFor = 'goals-for-desc',
	GoalsAgainst = 'goals-against-desc',
}

//start using from ui-kit in all places
export enum InternationalTournamentsType {
	ClubInt = 'clubint',
	TeamInt = 'teamint',
}

export enum AdvertisingTypes {
	DesktopInread = '90live_Desktop_Inread',
	Postscript = '90live_Postscript_News',
}

export enum PeriodType {
	RegularPeriod = 'regular_period',
	Overtime = 'overtime',
	Penalties = 'penalties',
}

export enum MethodScoreType {
	OwnGoal = 'own_goal',
	Penalty = 'penalty',
}

export enum UserRoles {
	ModeratorNews = 'moderator_news',
	ModeratorBlog = 'moderator_blog',
	ModeratorComment = 'moderator_comment',
	ModeratorTag = 'moderator_tag',
	ModeratorRoles = 'moderator_roles',
	ModeratorLogo = 'moderator_logo',
	ModeratorTeaser = 'moderator_teaser',
	ModeratorPlus = 'moderator_plus',
	ModeratorBroadcast = 'moderator_broadcast',
	User = 'user',
	UserBanCommentChat = 'user_ban_comment_chat',
	ModeratorBookie = 'moderator_bookie',
	ModeratorCasino = 'moderator_casino',
}

export enum Gender {
	FEMALE = 'Female',
	MALE = 'Male',
}

export enum TagTabPath {
	News = 'news',
	Blogs = 'blogs',
	Fixtures = 'fixtures',
	Teams = 'teams',
	Table = 'table',
	Squad = 'squad',
	Transfers = 'transfers',
	Career = 'career',
	Draw = 'draw',
	Bracket = 'bracket',
	Playoff = 'playoff',
	Stat = 'stat',
	Stats = 'stats',
}

export type Bonus = {
	description: Record<LANG, string>;
	priority: number;
	link: string;
};

export enum TagFixturesTabs {
	Fixtures = 'fixtures',
	Results = 'results',
}

export enum TagPlayerCareerTabs {
	Club = 'club',
	International = 'international',
}

export enum TournamentType {
	Club = 'club',
	International = 'international',
}

export type BookieBioType = Record<
	string,
	{
		title: string;
		list?: any[];
		isTrue?: boolean;
		item?: string;
		isCopied?: boolean;
		isSpoiler?: boolean;
		withPrompts?: boolean;
		tagColorType?: TypeColorTag;
	}
>;

export type Breadcrumb = {
	name: string;
	link?: string;
};

export enum Theme {
	DARK = 'dark',
	LIGHT = 'light',
}

export enum TypeColorTag {
	GREEN = 'green',
	YELLOW = 'yellow',
	BLUE = 'blue',
	GREY = 'grey',
}

export enum BonusType {
	Bonus = 'BONUS',
	Cashback = 'CASHBACK',
	Freebet = 'FREEBET',
	Special = 'SPECIAL',
}

export type TransferFilterType = {
	year: number;
	window: StatWindowTransfers;
	transfersType: StatInOut;
	sort: StatTransferSort;
};

export enum TransfersPageState {
	YearnAndSeason = 'year_and_season',
	Paging = 'paging',
	Default = 'default',
}

export enum TagTypesUrls {
	AllTags = 'tags',
	Clubs = 'clubs',
	NationalTeam = 'team',
	League = 'league',
	Persons = 'persons',
	Other = 'other',
}

export enum TagSubTypesUrls {
	Athlete = 'athlete',
	Coach = 'coach',
	Referee = 'referee',
	Manager = 'manager',
	Journalist = 'journalist',
}
export interface StatTeamSeasonFixturesWithSeparatedNextAndPrevMatches
	extends StatTeamSeasonRaw {
	pageListMatchesNext: StatMatchList;
	pageListMatchesPrev: StatMatchList;
}

export interface StatSeasonWithSeparatedNextAndPrevMatches extends StatSeason {
	pageListMatchesNext: StatMatchList;
	pageListMatchesPrev: StatMatchList;
}

export interface DayjsWithAddFunction extends Dayjs {
	isToday: (date?: Dayjs) => boolean;
}

export enum ContentTypeName {
	Post = 'Post',
	News = 'News',
}
export type RbWidgetData = {
	bookies: Bookie[];
	casinos: Casino[];
	totalBookieCount: number;
	totalCasinoCount: number;
};

export enum NewsFilterTabs {
	MAIN = 'main',
	ALL = 'all',
}
export enum PersonStatObjectType {
	Player = 'player',
	Coach = 'coach',
}

export type StatTournamentWitSeasonNames = StatTournament & {
	seasonNames?: string[];
	defaultSeasonName?: string;
};

export interface INativeAdsConfigItem {
	disableInfiniteScroll: boolean;
	disableAds: boolean;
	clickUrl: string;
	branding: {
		backgroundColor: string;
		logo: {
			svg?: string;
			img?: string;
		};
		mobileBranding: string[];
		mobileTooltip: {
			logo: {
				svg?: string;
				img?: string;
			};
		};
	};
}

export enum TopSectionIds {
	Live = 'live',
	Apps = 'apps',
}
