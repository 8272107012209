import { gql } from '@apollo/client';

const FOOTER_PRAGMENT = gql`
	fragment FooterBlock on FooterBlock {
		name
		links {
			text
			url
		}
	}
`;

export default {
	getFooter: gql`
		${FOOTER_PRAGMENT}
		query getFooter {
			linkQueries {
				getFooterBlocks {
					...FooterBlock
				}
			}
		}
	`,
};
