import { initializeApp } from 'firebase/app';
import {
	initializeAuth,
	indexedDBLocalPersistence,
	browserLocalPersistence,
	browserSessionPersistence,
} from 'firebase/auth';

import { FIREBASE_LANG_ENV } from './constants';

const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
const isItDevEnv =
	process.env.NEXT_PUBLIC_TRIBUNA_GATEWAY_PATH?.match(/stage\.|qa\.|dev\./g);

console.info({ isProd });

const firebaseConfig =
	isProd && !isItDevEnv
		? FIREBASE_LANG_ENV
		: {
				apiKey: 'AIzaSyAdO49d-8PrmGCs-iTVfqx2LPOc1h0QJaI',
				authDomain: 'authorization-test-80abd.firebaseapp.com',
				projectId: 'authorization-test-80abd',
				storageBucket: 'authorization-test-80abd.appspot.com',
				messagingSenderId: '935252358742',
				appId: '1:935252358742:web:5a5ec191bbf6e0831fedf7',
				measurementId: 'G-3W2Q8JHZLD',
		  };
// if a Firebase instance doesn't exist, create one
export const FirebaseApp = initializeApp(firebaseConfig);
// export const auth = getAuth(FirebaseApp);
export const auth = initializeAuth(FirebaseApp, {
	persistence: [
		indexedDBLocalPersistence,
		browserLocalPersistence,
		browserSessionPersistence,
	],
});

export default {
	FirebaseApp,
	auth,
};
