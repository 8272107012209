import { CasinoRatingItem, PriorityType } from '@ui-kit/main-api-types';

import { client as clientV2 } from '@apollo-client-v2';

import { casinoQueries } from './queries';

export const getCasino = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: casinoQueries.getCasino,
			variables: {
				id,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => {
			return {
				casino: res?.data?.casinoQueries?.get,
				errors: res?.errors
					?.map(error => error?.message)
					?.reduce((acc, e) => acc + e, ''),
			};
		});
};

export const getCasinoRating = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: casinoQueries.getCasinoRating,
			variables: {
				id,
				language: locale?.toUpperCase(),
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.casinoQueries?.getRating);
};

interface IGetCasinoRatingListing {
	locale: string;
	countryCode?: string;
	priorityType?: PriorityType;
	limit?: number;
}

export const getCasinoRatingListing = ({
	locale,
	countryCode = 'DE',
	priorityType = PriorityType.LanguagePriority,
	limit,
}: IGetCasinoRatingListing): Promise<CasinoRatingItem[]> =>
	clientV2
		.query({
			query: casinoQueries.getCasinoRatingListing,
			variables: {
				onlyRatingsFromMenu: true,
				priorityType,
				language: locale.toUpperCase(),
				limit,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.casinoQueries?.getRatingListing);

export const getCasinoWidgetLinks = ({ locale }) => {
	return clientV2
		.query({
			query: casinoQueries.getCasinoWidgetLinks,
			variables: {
				id: 'casino-int',
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': 'DE',
				},
			},
		})
		.then(res => {
			const rating = res?.data?.casinoQueries?.getRating;
			return [...rating?.topCasinos, ...rating?.otherCasinos];
		});
};

export const getCasinoWidgetData = ({
	locale,
	countryCode = 'DE',
	limit = 5,
}) => {
	return clientV2
		.query({
			query: casinoQueries.getCasinoWidgetData,
			variables: {
				id: 'casino-int',
				limit,
				language: locale?.toUpperCase(),
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => res?.data?.casinoQueries?.getRating);
};

export const getCasinoStickyButton = ({ id, locale, countryCode }) => {
	return clientV2
		.query({
			query: casinoQueries.getCasinoStickyButton,
			variables: {
				id,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'x-language': locale,
					'x-country-code': countryCode,
				},
			},
		})
		.then(res => {
			return {
				bookie: res?.data?.casinoQueries?.get,
				errors: res?.errors
					?.map(error => error?.message)
					?.reduce((acc, e) => acc + e, ''),
			};
		});
};
