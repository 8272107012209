import { ApolloQueryResult } from '@apollo/client';
import { client as clientV2 } from '@apollo-client-v2';
import { FooterBlock, LinkQueries } from '@ui-kit/main-api-types';

import queries from '@store/footer/queries';

export const getFooterLinks = async (locale): Promise<FooterBlock[]> => {
	const footerLinks: ApolloQueryResult<{ linkQueries: LinkQueries }> =
		await clientV2.query({
			query: queries.getFooter,
			errorPolicy: 'all',
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return footerLinks?.data?.linkQueries?.getFooterBlocks;
};
