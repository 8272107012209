const _private = {};

/**
 * Готовит данные для записи в localStorage
 *
 * @access private
 *
 * @param {*} data - Даные для записи
 *
 * @returns {String}
 *
 */
_private.stringifyData = function (data) {
	return JSON.stringify({
		time: Date.now(),
		data: data,
	});
};

/**
 * Парсит JSON
 *
 * @access private
 *
 * @param {String} data - Данные из localStorage
 *
 * @returns {*}
 *
 */
_private.parseData = function (data) {
	try {
		return JSON.parse(data);
	} catch (e) {
		return data;
	}
};

export default {
	set(key, value) {
		try {
			window.localStorage.setItem(key, _private.stringifyData(value));
		} catch (e) {
			return undefined;
		}
	},
	setNoTimeUpdate(key, value) {
		try {
			let oldDate = Date.now();
			if (window.localStorage.getItem(key)) {
				oldDate =
					_private.parseData(window.localStorage.getItem(key)).time ||
					Date.now();
			}
			window.localStorage.setItem(
				key,
				JSON.stringify({
					time: oldDate,
					data: value,
				}),
			);
		} catch (e) {
			console.warn({ e });
			return undefined;
		}
	},

	get(key) {
		try {
			return _private.parseData(window.localStorage.getItem(key)).data;
		} catch (e) {
			return undefined;
		}
	},

	setObject(key, data) {
		if (typeof data !== 'object') {
			return console.error('Utils local-storage: expect object');
		}

		let stringData = JSON.stringify(data);
		this.set(key, stringData);
	},

	getObject(key) {
		try {
			let stringData = this.get(key);

			if (stringData) {
				return JSON.parse(stringData);
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	},

	/**
	 * Удалят данные по ключу
	 *
	 * @access public
	 *
	 * @param {String} key - название ключа
	 *
	 * @return {Boolean|LocalStorage}
	 */
	remove(key) {
		window.localStorage.removeItem(key);

		return this;
	},

	/**
	 * Получает данные и проверяет время хранения
	 *
	 * @access public
	 *
	 * @param {String} key - название ключа
	 * @param {Number|String} timeLife - Время жизни данных ( 1d - один день | 10m - 10 минут ) default seconds
	 *
	 * @return {Boolean|Null|Object}
	 *
	 */
	getCheckTime(key, timeLife) {
		let data = _private.parseData(window.localStorage.getItem(key)),
			time = parseFloat(timeLife);
		if (!data) {
			return null;
		}

		switch (true) {
			case timeLife.search(/m/) > 0:
				time *= 60;
				break;
			case timeLife.search(/h/) > 0:
				time *= 60 * 60;
				break;
			case timeLife.search(/d/) > 0:
				time *= 60 * 60 * 24;
				break;
		}
		if (Date.now() - data.time > time * 1000) {
			this.remove(key);

			return null;
		}

		return data.data;
	},

	setItemForAWhile(key, value, validFor) {
		this.set(key, value);
		let terminating = this.get('target-list') || {};
		let time = parseFloat(validFor);
		switch (true) {
			case validFor.search(/m/) > 0:
				time *= 60;
				break;
			case validFor.search(/h/) > 0:
				time *= 60 * 60;
				break;
			case validFor.search(/d/) > 0:
				time *= 60 * 60 * 24;
				break;
		}
		terminating[key] = new Date(Date.now() + time * 1000);
		this.set('target-list', terminating);
	},

	// I am a sniper, always hit the mark
	clearStorage(version) {
		if (!this.get('storage:updated')) {
			try {
				for (let i = localStorage.length; i--; ) {
					let key = localStorage.key(i);
					if (key.indexOf(':article:') > 0 || key.indexOf(':news') > 0) {
						localStorage.removeItem(localStorage.key(i));
					}
				}
				this.set('storage:updated', true);
			} catch (e) {
				// server side
			}
		}
		// Paid assassin, working after dark
		let terminating = this.get('target-list') || {};
		// Looking through the night
		for (let key in terminating) {
			if (Object.hasOwnProperty.call(terminating, key)) {
				// Using infra-red
				let time = terminating[key];
				// My target on you
				const keyToRemoveCheck =
					this.get(key) &&
					key !== 'tribuna:sessionDepth' &&
					(new Date(time) < Date.now() || version !== this.get(key).version);

				if (keyToRemoveCheck) {
					// Aimed at your head
					this.remove(key);
					delete terminating[key];
				}
			}
		}
		this.set('target-list', terminating);
	},
};
