import { gql } from '@apollo/client';

import { STRUCTURED_BODY_BOOKIE, MULTI_LANG_STRING } from '@store/fragments';

const CASINO_GRADES = gql`
	fragment casinoGrades on CasinoGrades {
		tribuna
		allOffers
		welcomeOffer
		loginRegistrationProcess
		exchangeDepositWithdrawalsOptions
		security
		support
		usability
		iosApp
		androidApp
	}
`;

const CASINO_REDUCED_GRADES = gql`
	fragment casinoReducedGrades on CasinoReducedGrades {
		tribuna
		allOffers
		welcomeOffer
		loginRegistrationProcess
		exchangeDepositWithdrawalsOptions
		security
		support
		usability
		iosApp
		androidApp
	}
`;

const CASINO_DATA_BY_GEO = gql`
	${CASINO_GRADES}
	${CASINO_REDUCED_GRADES}
	${STRUCTURED_BODY_BOOKIE}
	${MULTI_LANG_STRING}
	fragment casinoDataByGeo on CasinoGeoSet {
		countryId
		isPartner
		returnToPlayer
		seo {
			title
			h1
			description
			canonical
		}
		currency {
			id
			name {
				...multiLangString
			}
		}
		maxSum
		minDep
		promoCodes
		license
		address
		mainCurrencies {
			id
			name {
				...multiLangString
			}
		}
		mainPaymentSystems
		faqs {
			title
			structuredBody {
				...StructuredBodyFragmentForBookie
			}
		}
		grades {
			...casinoGrades
		}
		reducedGrades {
			...casinoReducedGrades
		}
		bonuses {
			id
			casinoId
			countryId
			name
			description
			shortDescription
			imageURL
			priority
			bonusType
			bonusText
			amount
			link
			promoCode
			currency {
				id
				name {
					...multiLangString
				}
			}
		}
	}
`;

export const casinoQueries = {
	getCasino: gql`
		${CASINO_GRADES}
		${CASINO_DATA_BY_GEO}
		${CASINO_REDUCED_GRADES}
		${STRUCTURED_BODY_BOOKIE}
		${MULTI_LANG_STRING}
		query GetCasino($id: ID!) {
			casinoQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					url {
						link
						linkType
						linkText
					}
					name
					isBanned
					logoURL
					updatedAt
					publicationTime
					appIOSButtonURL
					appAndroidButtonURL
					pros
					cons
					seo {
						title
						h1
						description
						canonical
					}
					author {
						id
						name
						bio
						avatar {
							webp(width: 60, height: 60, quality: 80)
						}
					}
					faqs {
						title
						structuredBody {
							...StructuredBodyFragmentForBookie
						}
					}
					bio {
						providers {
							id
							name {
								...multiLangString
							}
						}
						liveChat
						platforms
						products
						currencies {
							id
							name {
								...multiLangString
							}
						}
						paymentSystems
						foundationYear
						owner
						siteLanguages {
							id
							name {
								...multiLangString
							}
						}
						licenceCountries {
							id
							name {
								...multiLangString
							}
						}
						bannedCountries {
							id
							name {
								...multiLangString
							}
						}
					}
					structuredSeoText {
						...StructuredBodyFragmentForBookie
					}
					dataByGeo {
						...casinoDataByGeo
					}
					grades {
						...casinoGrades
					}
					reducedGrades {
						...casinoReducedGrades
					}
					existingTranslations
				}
			}
		}
	`,

	getCasinoRating: gql`
		${CASINO_GRADES}
		${CASINO_REDUCED_GRADES}
		${STRUCTURED_BODY_BOOKIE}
		query GetCasinoRating($id: ID!, $language: Language) {
			casinoQueries {
				getRating(input: { id: $id, idType: HRU }) {
					id
					hru
					name
					countryId
					ratingType
					existingTranslations
					structuredDescription {
						...StructuredBodyFragmentForBookie
					}
					structuredSeoText {
						...StructuredBodyFragmentForBookie
					}
					sortType
					faqs {
						title
						structuredBody {
							...StructuredBodyFragmentForBookie
						}
					}
					author {
						id
						name
						bio
						avatar {
							webp(width: 60, height: 60, quality: 80)
						}
					}
					seo {
						h1
						title
						description
						canonical
					}
					topCasinos {
						id
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						appIOSButtonURL
						appAndroidButtonURL
						structuredSeoText {
							type
						}
						grades {
							...casinoGrades
						}
						reducedGrades {
							...casinoReducedGrades
						}
						dataByGeo {
							isPartner
							grades {
								...casinoGrades
							}
							reducedGrades {
								...casinoReducedGrades
							}
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
						}
					}
					otherCasinos {
						id
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						appIOSButtonURL
						appAndroidButtonURL
						structuredSeoText {
							type
						}
						grades {
							...casinoGrades
						}
						reducedGrades {
							...casinoReducedGrades
						}
					}
					updatedAt
				}
			}
		}
	`,

	getCasinoRatingListing: gql`
		query GetRatingListing(
			$onlyRatingsFromMenu: Boolean!
			$priorityType: PriorityType!
			$language: Language
			$limit: Int
		) {
			casinoQueries {
				getRatingListing(
					input: {
						onlyRatingsFromMenu: $onlyRatingsFromMenu
						priorityType: $priorityType
						limit: $limit
					}
				) {
					hru
					name
					casinos {
						hru
						name
						logoURL
						url {
							link
							linkText
						}
						structuredSeoText {
							type
						}
						dataByGeo {
							isPartner
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
						}
					}
				}
			}
		}
	`,

	getCasinoWidgetLinks: gql`
		query GetCasinoWidgetLinks($id: ID!) {
			casinoQueries {
				getRating(input: { id: $id, idType: HRU, limit: 5 }) {
					topCasinos {
						hru
						name
						existingTranslations
					}
					otherCasinos {
						hru
						name
						existingTranslations
					}
				}
			}
		}
	`,

	getCasinoWidgetData: gql`
		query GetCasinoWidgetData($id: ID!, $language: Language, $limit: Int) {
			casinoQueries {
				getRating(input: { id: $id, idType: HRU, limit: $limit }) {
					id
					hru
					name
					totalCasinosCount
					topCasinos {
						id
						hru
						name
						existingTranslations
						logoURL
						url {
							link
							linkText
						}
						dataByGeo {
							isPartner
							bonuses {
								bonusType
								bonusText
								amount
								currency {
									id
									name {
										defaultValue(language: $language)
									}
								}
								link
							}
						}
					}
					otherCasinos {
						id
						hru
						name
						logoURL
						existingTranslations
						url {
							link
							linkText
						}
					}
				}
			}
		}
	`,

	getCasinoStickyButton: gql`
		query GetCasinoStickyButton($id: ID!) {
			casinoQueries {
				get(input: { id: $id, idType: HRU }) {
					id
					hru
					url {
						link
					}
					name
					isBanned
					logoURL
					dataByGeo {
						bonuses {
							bonusType
							priority
							bonusText
							amount
							link
							promoCode
							currency {
								id
							}
						}
					}
				}
			}
		}
	`,
};
