import { gql } from '@apollo/client';

import {
	LOCALIZED_STRING_FRAGMENT,
	LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT,
} from '@store/fragments';

export default {
	currentUser: gql`
		query currentUser {
			userQueries {
				current {
					id
					banned {
						type
						until
					}
					avatar {
						resize(width: 40, height: 40)
						url(width: 40, height: 40)
					}
					name
					email
					roles {
						ID
					}
					currentAuthProvider
				}
			}
		}
	`,

	getUser: gql`
		query getUserInfo($id: ID!) {
			userQueries {
				get(input: { id: $id }) {
					id
					name
					createTime
					avatar {
						webp(width: 80, height: 80, quality: 80)
						url
					}
					rating {
						rating
						position
					}
					location {
						country {
							name
							code
							picture(productType: TRIBUNA, format: PNG) {
								main
							}
						}
						city
					}
					bio
					roles {
						ID
					}
				}
			}
		}
	`,

	getUserComments: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		query getUserComments($id: ID!, $page: Int = 1, $pageSize: Int = 10) {
			commentsQueries {
				ByUser(
					input: {
						userID: $id
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
					}
				) {
					items {
						id
						text
						created
						objectClass
						application
						reactions {
							likeCount
							dislikeCount
						}
						isReplyToTopLevelComment
						parentComment {
							text
							user {
								id
								name
							}
						}
						parentObject {
							__typename
							... on Post {
								id
								hru
								title {
									...localizedString
								}
								structuredBody {
									...localizedBlogPostDescription
								}
								blog {
									id
									hru
								}
								publicationTime
								application
							}
							... on News {
								id
								hru
								newsTitle: title {
									...localizedString
								}
								structuredBody {
									...localizedBlogPostDescription
								}
								publicationTime
								application
							}
						}
					}
					paginationInfo {
						... on PaginationByPage {
							hasNextPage
						}
					}
				}
			}
		}
	`,

	getUserCommentsContentAuthor: gql`
		query getUserCommentsContentAuthor(
			$id: ID!
			$page: Int = 1
			$pageSize: Int = 10
		) {
			commentsQueries {
				ByUser(
					input: {
						userID: $id
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
					}
				) {
					items {
						id
						parentObject {
							... on Post {
								displayAuthor {
									user {
										id
										name
									}
								}
								author {
									user {
										id
										name
									}
								}
							}
							... on News {
								displayAuthor {
									user {
										id
										name
									}
								}
								newsAuthor: author {
									user {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	`,

	getUserCommentsAvailability: gql`
		query getUserCommentsAvailability($id: ID!) {
			commentsQueries {
				ByUser(
					input: {
						userID: $id
						pagination: { ByPage: { page: 1, pageSize: 10 } }
					}
				) {
					items {
						id
					}
				}
			}
		}
	`,

	getUserNews: gql`
		${LOCALIZED_STRING_FRAGMENT}
		query getUserNews(
			$page: Int = 1
			$pageSize: Int = 40
			$lang: Language
			$status: DocumentStatus = PUBLISHED
			$author: String
		) {
			newsQueries {
				feed(
					input: {
						status: $status
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						author: $author
					}
				) {
					items {
						id
						hru
						commentsCount
						isMainSection
						url(input: { useAvailableLang: true })
						newsTitle: title {
							...localizedString
							defaultValue(language: $lang)
						}
						label
						displayAuthor {
							user {
								id
								name
							}
						}
						newsAuthor: author {
							user {
								id
								name
							}
						}
						mainPhoto {
							url
							webp(width: 250, height: 140, quality: 80)
						}
						mainSport {
							path
							name {
								defaultValue(language: $lang)
							}
							id
						}
						publicationTime
						languages
					}
					paginationInfo {
						... on PaginationByPage {
							lastPage
						}
					}
				}
			}
		}
	`,

	getUserNewsAvailability: gql`
		query getUserNewsAvailability($author: String) {
			newsQueries {
				feed(
					input: {
						status: PUBLISHED
						pagination: { ByPage: { page: 1, pageSize: 10 } }
						author: $author
					}
				) {
					items {
						id
					}
				}
			}
		}
	`,

	getCountryList: gql`
		query getCountryList {
			stat_countryActiveList {
				name
				code
				picture(productType: TRIBUNA, format: PNG) {
					main
				}
			}
		}
	`,
};
