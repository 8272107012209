/**
 * Мобильный ли девайс
 */

import { GOOGLE_PAGESPEED_USER_AGENT } from './constants';

export function isMobile(): boolean {
	if (typeof window !== 'undefined') {
		return window && window.innerWidth < 660;
	}
	return false;
}

// Mobile device detection, based on MDN solution:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export function isMobileDeviceMDNCheck(): boolean {
	if (typeof navigator === 'undefined') {
		return false;
	}
	if ('maxTouchPoints' in navigator) {
		return (
			navigator.maxTouchPoints > 0 && 'ontouchstart' in document.documentElement
		);
	}
	if ('msMaxTouchPoints' in navigator) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return navigator?.msMaxTouchPoints > 0;
	}
	if (typeof window === 'undefined') {
		return false;
	}
	const mQ = window?.matchMedia && matchMedia('(pointer:coarse)');
	if (mQ && mQ.media === '(pointer:coarse)') {
		return !!mQ.matches;
	}
	if ('orientation' in window) {
		return true; // deprecated, but good fallback
	}
	// Only as a last resort, fall back to user agent sniffing
	const UA = navigator?.['userAgent'];
	return (
		/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
		/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
	);
}

export function getCookie(name: string): string {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

/**
 * Check isItBot or not
 * @returns {boolean}
 */
export function isItBotCheck() {
	let isItBot = false;
	const botRegex = /lighthouse|googlebot|gtmetrix|HeadlessChrome/gi;
	if (typeof window !== 'undefined') {
		isItBot =
			!!window?.navigator?.userAgent?.match?.(botRegex) ||
			!!window?.navigator?.userAgent?.match?.(
				new RegExp(GOOGLE_PAGESPEED_USER_AGENT, 'gi'),
			) ||
			!!window['__IsItNativeAd'];
	}
	return isItBot;
}

export function getPlatform() {
	const ua = window.navigator.userAgent;
	if (/(iPhone|iPad|iPod)/gi.test(ua)) {
		return 'ios';
	}
	if (ua.indexOf('Android') > -1) {
		return 'android';
	}
	return 'other';
}

/**
 * Сравнение двух объектов
 */
export function objCompare(mainObj: object, objToCompare: object): boolean {
	const keysOfMainObj = Object.keys(mainObj);
	const keysOfObjToCompare = Object.keys(objToCompare);
	if (keysOfMainObj.length === keysOfObjToCompare.length) {
		return keysOfMainObj.length
			? !keysOfMainObj.find(key => {
					return mainObj[key] !== objToCompare[key];
				})
			: false;
	} else {
		return false;
	}
}
/**
 * Объединение двух массивов
 */
export function arrayComplement<T>(set: Array<T>, sub: Array<T>): Array<T> {
	if (Array.isArray(set && sub)) {
		return set.filter(e2 => !sub.some(e1 => e2 === e1));
	} else {
		return [];
	}
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray<T>(
	myArray: Array<T>,
	chunk_size: number,
): Array<T>[] {
	const results = [];

	while (myArray.length) {
		results.push(myArray.splice(0, chunk_size));
	}

	return results;
}

/**
 * Настольный ли девайс
 * @returns {boolean}
 */
export function isDesktop(): boolean {
	if (typeof window !== 'undefined') {
		return window.innerWidth > 1316;
	}
	return false;
}

export function isLargeMobile() {
	return isMobileDeviceMDNCheck() && window.innerHeight > 600;
}

export function copyText(text: string): void {
	if (navigator.clipboard) {
		navigator.clipboard.writeText(text).then(() => {});
	} else {
		const el = document.createElement('textarea');
		el.value = text;
		document.body.appendChild(el);

		const ua = window.navigator.userAgent;
		if (/(iPhone|iPad|iPod)/gi.test(ua)) {
			const editable = el.contentEditable;
			const readOnly = el.readOnly;

			el.contentEditable = String(true);
			el.readOnly = true;

			const range = document.createRange();
			range.selectNodeContents(el);

			const selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			el.setSelectionRange(0, 999999);

			el.contentEditable = editable;
			el.readOnly = readOnly;
		} else {
			el.select();
		}

		document.execCommand('copy');
		document.body.removeChild(el);
	}
}

export default {
	isDesktop,
	chunkArray,
	isMobile,
	isMobileDeviceMDNCheck,
	getCookie,
	isItBotCheck,
	copyText,
	objCompare,
	arrayComplement,

	getPlatform,
	isLargeMobile,
};
