import { gql } from '@apollo/client';

export default {
	googleLogin: gql`
		mutation GoogleSignIn($authToken: String!) {
			authMutations {
				googleSignin(authToken: $authToken) {
					session {
						token
						user {
							id
							name
							email
							avatar {
								resize(width: 40, height: 40)
							}
							banned {
								type
								until
							}
						}
						deviceId
					}
				}
			}
		}
	`,

	firebaseLogin: gql`
		mutation firebaseSignin(
			$authToken: String!
			$signinType: FirebaseSigninType!
		) {
			authMutations {
				firebaseSignin(idToken: $authToken, signinType: $signinType) {
					session {
						token
						user {
							id
							name
							email
							avatar {
								url
								resize(width: 40, height: 40)
							}
						}
						deviceId
					}
				}
			}
		}
	`,

	pingDeviceId: gql`
		mutation PingDeviceId(
			$screen: String!
			$build: String!
			$userAgent: String!
			$platform: String!
			$timezoneOffset: Int!
		) {
			pingDevice(
				input: {
					screen: $screen
					build: $build
					userAgent: $userAgent
					platform: $platform
					timezoneOffset: $timezoneOffset
				}
			) {
				deviceId
				userId
			}
		}
	`,
	banUser: gql`
		mutation banUser($userId: ID!) {
			usersMutations {
				ban(input: { userID: $userId })
			}
		}
	`,
	shadowBanUser: gql`
		mutation ShadowBanUser($userId: ID!) {
			userMutations {
				shadowBan(input: { userID: $userId }) {
					message
					success
				}
			}
		}
	`,

	getUploadUrl: gql`
		mutation GetUploadUrl($type: PictureType!) {
			picturesMutations {
				uploadByFile(input: { type: $type }) {
					uploadURL
					picture {
						url
						webp
					}
				}
			}
		}
	`,

	userProfileUpdate: gql`
		mutation userProfileUpdate(
			$bio: String
			$nickname: String
			$countryCode: String
			$city: String
			$avatarUrl: URL
		) {
			usersMutations {
				updateProfile(
					input: {
						profile: {
							bio: $bio
							nickname: $nickname
							location: { countryCode: $countryCode, city: $city }
							avatarUrl: $avatarUrl
						}
					}
				) {
					id
				}
			}
		}
	`,
	recreateEmailAuthForUser: gql`
		mutation recreateEmailAuthForUser($email: String!) {
			authMutations {
				recreateEmailAuthForUser(input: { email: $email })
			}
		}
	`,
};
